import { TakeCommandLogoInline } from "@/components/Branding"
import { appConfig } from "@/config"
import { Button, Chip, Grid, Link, Typography } from "@mui/material"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
// import external links
import { EXTERNAL_LINKS } from "@/constants"

import { mfaEmailCodeSend } from "@/features/Auth/pages/Mfa/mfaService"
import { useEmailSendCodeStore } from "@/features/Auth/pages/Mfa/VerifyEmailCodeMfa"
import { useLoginRedirect } from "@/services/loginRedirectService"
import { colors } from "@/theme/palette"
import { useAuth } from "../../useAuth"

export const MfaSelection = () => {
  const { user, signOut } = useAuth()
  const { redirect } = useLoginRedirect()
  const navigate = useNavigate()
  const location = useLocation()
  const { email: stateEmail, isLoggedIn, password } = location.state ?? {}
  const email = stateEmail ?? user?.email ?? ""
  const { markLastSentCode } = useEmailSendCodeStore()

  useEffect(() => {
    if (!email) {
      navigate("/")
    }
  }, [email, navigate])

  const handleEmailSelection = async () => {
    // trigger it without waiting for the promise to resolve, we don't want to stop the screen transition of the navigation
    mfaEmailCodeSend(email, password).catch(console.error)
    markLastSentCode()
    navigate("/mfa/verify/code", {
      state: {
        ...(location.state ?? {}),
        email,
      },
    })
  }
  // FUTURE: SEG-6212 - Commenting out until mfa is ready
  // const handleSmsSelection = () => {
  //   navigate("/mfa/setup/sms", {
  //     state: {
  //       ...(location.state ?? {}),
  //       email,
  //     },
  //   })
  // }

  const handleAuthAppSelection = () => {
    navigate("/mfa/setup", {
      state: {
        ...(location.state ?? {}),
        email,
      },
    })
  }

  const handleSignOut = () => {
    signOut()
    navigate("/")
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ maxWidth: "33.667rem" }}>
      <Grid item>
        <TakeCommandLogoInline />
      </Grid>
      <Grid item>
        <Chip
          label={email}
          color="default"
          sx={{
            mt: 8,
            border: "1pt solid black",
          }}
        />
      </Grid>
      <Grid item>
        <Typography align="center" variant="h1" sx={{ mt: 8 }}>
          Set Up Two-Step Authentication
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" sx={{ mt: 6 }}>
          Two-step authentication adds another level of security to your account. After you set up two-step
          authentication, you can sign in to your account with:
          <ul>
            <li>Your password</li>
            <li>Your phone</li>
          </ul>
        </Typography>
      </Grid>
      <Grid container>
        <Button variant="outlined" fullWidth sx={{ mt: 3 }} onClick={handleAuthAppSelection}>
          Use an Authenticator App
        </Button>
        <Button variant="outlined" fullWidth sx={{ mt: 3 }} onClick={handleEmailSelection}>
          Send code through Email
        </Button>
        <Button
          variant="outlined"
          disabled
          fullWidth
          sx={{ mt: 3 }}
          // Commenting out onclick until mfa is ready
          // onClick={handleSmsSelection}
        >
          Send code through SMS
        </Button>
      </Grid>
      <Typography variant="caption" sx={{ color: colors.darkBody, mt: 2 }}>
        <strong>SMS for MFA is coming soon!</strong>
      </Typography>
      <Typography variant="body1" sx={{ mt: 6 }}>
        <strong>Important:</strong> Never give your verification codes to anyone
      </Typography>
      <Grid sx={{ mt: 8 }}>
        <Typography variant="body1">
          <Button
            variant="text"
            onClick={() => {
              handleSignOut()
            }}
          >
            Switch account
          </Button>
        </Typography>
      </Grid>
      <Grid sx={{ mt: 12 }}>
        <Typography variant="caption">
          <Link href={EXTERNAL_LINKS.PRIVACY}>Privacy Policy</Link> |{" "}
          <Link href={EXTERNAL_LINKS.TERMS_OF_SERVICE}>Terms of Service</Link>
        </Typography>
      </Grid>
      {isLoggedIn && !appConfig.isProduction && (
        <Button onClick={() => redirect()} fullWidth sx={{ mt: 4 }} data-qa="skip-button">
          Skip
        </Button>
      )}
    </Grid>
  )
}
