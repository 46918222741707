import { LoadingMasonryArea } from "@/components/LoadingContentArea"
import { StyledCard } from "@/components/StyledCard"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { DashboardLayout } from "@/features/Dashboard/components/DashboardLayout"
import {
  ACTIVE,
  APPLICATION_AWAITING_DOCUMENTS,
  APPLICATION_DENIED,
  APPLICATION_PENDING,
  BANK_ACCOUNT_PENDING,
  CLOSED,
  FROZEN,
  NOT_STARTED,
  REAUTHENTICATION_NEEDED,
} from "@/features/Funding/fundingConstants"
import { useGetPaymentAccountInformation } from "@/features/Funding/fundingService"
import { FundingStatus } from "@/features/Funding/fundingTypes"
import { useGetPersonById } from "@/features/People/peopleService"
import { PersonModel } from "@/features/People/peopleTypes"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { getDisplayPhoneNumber } from "@/utils/formatting"
import { ContentCopyOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { Chip, ClickAwayListener, Divider, Grid, IconButton, Stack, SxProps, Tooltip, Typography } from "@mui/material"
import { memoize } from "lodash"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { ChangePasswordModal } from "./ChangePasswordModal"

export interface ContactCardProps {
  emailNew: string
  phoneNumber: string
}

export const ContactCard = ({ emailNew, phoneNumber }: ContactCardProps) => (
  <StyledCard data-qa="profile-contact-card">
    <Typography variant="h5">Contact</Typography>
    <Divider sx={{ my: 3 }} />
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="caption">Email</Typography>
        <Typography variant="body1">{emailNew}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">Phone Number</Typography>
        <Typography variant="body1">{phoneNumber}</Typography>
      </Grid>
    </Grid>
  </StyledCard>
)

export interface AboutCardProps {
  companyName: string
  fullAddress: string
  role: string
  editPassword?: () => void
}

export const AboutCard = ({ companyName, editPassword, fullAddress, role }: AboutCardProps) => (
  <StyledCard data-qa="profile-about-card">
    <Typography variant="h5">About</Typography>
    <Divider sx={{ my: 3 }} />
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="caption">Company</Typography>
        <Typography variant="body1">{companyName}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">Role</Typography>
        <Typography variant="body1">{role}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">Address</Typography>
        <Typography variant="body1">{fullAddress}</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="caption">Password</Typography>
            <Typography variant="body1">*******</Typography>
          </Grid>
          {editPassword && (
            <Grid item>
              <IconButton onClick={editPassword}>
                <ModeEditIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  </StyledCard>
)

interface FundingStatusStatusChipProps {
  dataQa?: string
  fundingStatus: FundingStatus
  sx?: SxProps
}

export const getVariantConfigByStatus = memoize((fundingStatus: string) => {
  switch (fundingStatus.toUpperCase()) {
    case ACTIVE:
      return {
        label: "Active",
        backgroundColor: colors.gumDropGreen,
        color: takeCommandPrimary.main,
      } as const
    case APPLICATION_DENIED:
      return {
        label: "Exipired",
        backgroundColor: colors.seaPink,
        color: colors.glazedRinglet,
      } as const
    case NOT_STARTED:
      return {
        label: "Not Started",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
    case APPLICATION_AWAITING_DOCUMENTS:
      return {
        label: "Awaiting Documents",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
    case BANK_ACCOUNT_PENDING:
      return {
        label: "Reauthentication Needed",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
    case REAUTHENTICATION_NEEDED:
      return {
        label: "Awaiting Documents",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
    case FROZEN:
      return {
        label: "Frozen",
        backgroundColor: colors.seaPink,
        color: colors.white,
      } as const
    case APPLICATION_PENDING:
    default:
      return {
        label: "Pending",
        backgroundColor: colors.lightTealShade,
        color: colors.waterBlue,
      } as const
  }
})

export const FundingStatusChip = ({ dataQa, fundingStatus, sx }: FundingStatusStatusChipProps) => {
  const variantConfig = getVariantConfigByStatus(fundingStatus)

  return (
    <Chip
      data-qa={createDataQa(dataQa, "funding-status-chip")}
      sx={{
        backgroundColor: variantConfig.backgroundColor,
        color: variantConfig.color,
        px: 2,
        ...sx,
      }}
      label={<Typography variant="body2">{variantConfig.label}</Typography>}
    />
  )
}
export interface PersonPaymentAccountInformationProps {
  bankName: string
  accountNumber: string
  routingNumber: string
  fundingStatus: FundingStatus
  error: any
}

const PersonPaymentAccountInformatation = ({
  bankName,
  accountNumber,
  routingNumber,
  fundingStatus,
  error,
}: PersonPaymentAccountInformationProps) => {
  const [isVisible, setIsVisible] = useState({ accountNumber: false, routingNumber: false })
  const [isCopied, setIsCopied] = useState({ accountNumber: false, routingNumber: false })

  const copyToClipboard = async (field: "accountNumber" | "routingNumber", value: string) => {
    await navigator.clipboard.writeText(value)
    setIsCopied(prev => ({ ...prev, [field]: true }))
    setTimeout(() => setIsCopied(prev => ({ ...prev, [field]: false })), 2000)
  }

  const maskValue = (value: string) => value?.replace(/.(?=.{4})/g, "*")

  const obsureValue = (value: "accountNumber" | "routingNumber") => {
    setIsVisible(prev => ({ ...prev, [value]: !prev[value] }))
  }

  return (
    <StyledCard data-qa="person-payment-account-information">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Payment Information</Typography>
        </Grid>
        <Grid item>
          <FundingStatusChip fundingStatus={fundingStatus} />
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="caption">Bank Name</Typography>
              <Typography variant="body1">{bankName}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="caption">Account Number</Typography>
              <Typography variant="body1">
                {isVisible.accountNumber ? accountNumber : maskValue(accountNumber)}
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={0.5}>
                <ClickAwayListener onClickAway={() => setIsCopied({ accountNumber: false, routingNumber: false })}>
                  <Tooltip
                    title="Copied!"
                    placement="top"
                    open={isCopied.accountNumber}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton
                      aria-label="Copy account number"
                      edge="end"
                      onClick={() => copyToClipboard("accountNumber", accountNumber)}
                      data-qa={createDataQa("copy-account-number")}
                    >
                      <ContentCopyOutlined />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
                <IconButton
                  aria-label="Account number visibility"
                  edge="end"
                  onClick={() => obsureValue("accountNumber")}
                >
                  {isVisible.accountNumber ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="caption">Routing Number</Typography>
              <Typography variant="body1">
                {isVisible.routingNumber ? routingNumber : maskValue(routingNumber)}
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={0.5}>
                <ClickAwayListener onClickAway={() => setIsCopied({ accountNumber: false, routingNumber: false })}>
                  <Tooltip
                    title="Copied!"
                    placement="top"
                    open={isCopied.routingNumber}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton
                      aria-label="Copy account number"
                      edge="end"
                      onClick={() => copyToClipboard("routingNumber", routingNumber)}
                      data-qa={createDataQa("copy-account-number")}
                    >
                      <ContentCopyOutlined />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
                <IconButton
                  aria-label="Account number visibility"
                  edge="end"
                  onClick={() => obsureValue("routingNumber")}
                >
                  {isVisible.routingNumber ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item mt={4}>
          <Typography variant="caption">Do not share your payment information.</Typography>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

const getRoleFromPerson = (person: PersonModel) => person?.roles?.map(role => role.name).join(", ")

export const Profile = () => {
  const { user } = useAuth()
  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const personId = user?.id
  const companyId = getActiveCompany(user)?.companyId || ""
  const editPassword = () => setIsChangingPassword(show => !show)
  const { data: person, isLoading: isLoadingPerson } = useGetPersonById(companyId, personId)
  const { data: company, isLoading: isCompanyLoading } = useGetCompany(companyId)
  const {
    data: fundingEntityInformation,
    isLoading: isLoadingFundingEntityInformation,
    isError: isFundingError,
  } = useGetPaymentAccountInformation(companyId, person?.employmentId ?? "")
  // About info
  const fullName = `${person?.firstName} ${person?.lastName}`
  const companyName = company?.companyInfo.companyName ?? ""
  const fullAddress = person?.location ?? ""
  // FUTURE SEG-5124 fix API for returing list of roles
  const role = getRoleFromPerson(person!) ?? ""

  // Contact info
  const emailNew = person?.email ?? ""
  const phoneNumber = getDisplayPhoneNumber(person?.phoneNumber ?? "")

  // Payment info
  const bankName = fundingEntityInformation?.bankName ?? ""
  const accountNumber = fundingEntityInformation?.accountNumber ?? ""
  const routingNumber = fundingEntityInformation?.routingNumber ?? ""
  const fundingStatus = (fundingEntityInformation?.status as FundingStatus) ?? APPLICATION_PENDING

  const isEmployeeInformationLoading = isLoadingPerson || isCompanyLoading || isLoadingFundingEntityInformation
  const isAccountClosed = fundingStatus === CLOSED

  return (
    <AuthGuard>
      <DashboardLayout>
        <Helmet title="Profile" />
        <Typography variant="h1" gutterBottom display="inline">
          {fullName}
        </Typography>
        {isEmployeeInformationLoading ? (
          <Grid item mt={10}>
            <LoadingMasonryArea data-qa="loading-employee-profile" />
          </Grid>
        ) : (
          <>
            <Grid container spacing={4} sx={{ mt: 1 }}>
              <Grid item xs={12} lg={4} xl={3}>
                <AboutCard
                  companyName={companyName}
                  fullAddress={fullAddress}
                  role={role}
                  editPassword={editPassword}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                {!isFundingError && !isAccountClosed && (
                  <PersonPaymentAccountInformatation
                    bankName={bankName}
                    accountNumber={accountNumber}
                    routingNumber={routingNumber}
                    fundingStatus={fundingStatus}
                    error={isFundingError}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4} xl={3}>
                <ContactCard emailNew={emailNew} phoneNumber={phoneNumber} />
              </Grid>
            </Grid>
          </>
        )}
        {isChangingPassword && (
          <ChangePasswordModal onClose={() => setIsChangingPassword(false)} editPassword={editPassword} />
        )}
      </DashboardLayout>
    </AuthGuard>
  )
}
