import { ClearableSearchInput } from "@/components/ClearableSearchInput/ClearableSearchInput"
import { SkeletonTableLoader } from "@/components/SkeletonTableLoader"
import { BaseTable } from "@/components/Table/Table"
import { TABLE_CELL_PADDING } from "@/constants"
import { useAutoPayCompanies, useFundingEvents } from "@/features/TCHub/tcHubService"
import { FundingEventModel, FundingEventsTableHeader } from "@/features/TCHub/tcHubTypes"
import { DATE_FORMAT_MONTH_DAY_YEAR, toTitleCase, transformDate } from "@/utils/formatting"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import { Button, Grid, TableCell, Typography } from "@mui/material"
import { constant } from "lodash"
import { useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { TcHubGuard } from "../../../Auth/guards/TcHubGuard"
import { CompanyModel } from "../../../CreateCompany/createCompanyEndpoints"
import { AddNewFundingEventModal } from "./AddNewFundingEventModal"

const headers: FundingEventsTableHeader[] = [
  { id: "companyName", label: "Company Name", sortable: true, field: "companyName", alignment: "left" },
  {
    id: "timePeriod",
    label: "Time Period",
    sortable: true,
    field: "timePeriod",
    alignment: "left",
  },
  {
    id: "timePeriodLabel",
    label: "Description",
    sortable: true,
    field: "timePeriodLabel",
    alignment: "left",
  },
  {
    id: "periodStartAt",
    label: "Start Date",
    sortable: true,
    field: "periodStartAt",
    alignment: "left",
  },
  {
    id: "periodEndAt",
    label: "End Date",
    sortable: true,
    field: "periodEndAt",
    alignment: "left",
  },
  {
    id: "status",
    label: "Status",
    sortable: true,
    field: "status",
    alignment: "left",
  },
]

export const CompanySet = (companies: CompanyModel[]) => {
  const companySet = new Map<string, string>()

  companies.forEach(company => {
    companySet.set(company.id, company.companyInfo.companyName)
  })

  return companySet
}

const matchAccountName = (fundingEvent: FundingEventModel, searchQueryLowerCase: string) =>
  (fundingEvent.name ?? "").toLowerCase().includes(searchQueryLowerCase)

const matchCompanyName = (
  fundingEvent: FundingEventModel,
  companyId: string,
  companySet: Map<string, string>,
  searchQueryLowerCase: string
) => companySet.get(companyId)?.toLowerCase().includes(searchQueryLowerCase)

export const TcHubFundingEventsPage = () => {
  const navigate = useNavigate()
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const [showNewFundingEventModal, setShowNewFundingEventModal] = useState(false)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(25)

  const { data: fundingEvents, isLoading: isFundingEventsLoading } = useFundingEvents()
  const { data: companies, isLoading: isCompaniesLoading } = useAutoPayCompanies()

  const companySet = useMemo(() => CompanySet(companies ?? []), [companies])

  const filteredFundingEvents = useMemo(
    () =>
      (fundingEvents ?? [])
        .filter((fundingEvent: FundingEventModel) => {
          const searchQueryLowerCase = searchInputValue.toLowerCase()

          return (
            matchAccountName(fundingEvent, searchQueryLowerCase) ||
            matchCompanyName(fundingEvent, fundingEvent.companyId, companySet, searchQueryLowerCase)
          )
        })
        .map(fundingEvent => ({
          ...fundingEvent,
          companyName: companySet.get(fundingEvent.companyId),
        })),
    [fundingEvents, searchInputValue, companySet]
  )

  const closeFundingEventModal = () => {
    setShowNewFundingEventModal(false)
  }

  const openFundingEventModal = () => {
    setShowNewFundingEventModal(true)
  }

  return (
    <TcHubGuard requiredPermissions={["tc_hub_autopay"]}>
      <Grid container data-qa="tc-hub-funding-events-page">
        <Helmet title="TC Hub Funding Events" />
        <Grid item xs={12} mb={5}>
          <Typography variant="h1" display="inline" data-qa="funding-events">
            Funding Events
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <ClearableSearchInput
              onChange={event => setSearchInputValue(event.target.value)}
              handleClearClick={() => setSearchInputValue("")}
              data-qa="funding-events-search-input"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={openFundingEventModal}
              sx={{ float: "right" }}
              startIcon={<AddOutlinedIcon />}
            >
              Add new funding event
            </Button>
          </Grid>
          <AddNewFundingEventModal
            onClose={closeFundingEventModal}
            showNewFundingEventModal={showNewFundingEventModal}
          />
        </Grid>
        {!isCompaniesLoading || !isFundingEventsLoading ? (
          <BaseTable
            rows={filteredFundingEvents}
            selected={[]}
            searchCriteria=""
            onToggleSelect={() => []}
            onToggleOrderBy={() => []}
            onToggleSelectAll={() => []}
            onPageChange={changedPage => setPage(changedPage)}
            onRowsPerPageChange={rows => setRowsPerPage(rows)}
            uniqueIdSelector={constant("")}
            headCells={headers ?? []}
            rowsPerPage={rowsPerPage}
            page={page}
            fullWidth
            orderBy={[{ headCellId: "", direction: "asc" }]}
            onRowClick={(row: FundingEventModel) => {
              navigate(`${row.companyId}/${row.id}`)
            }}
            exportCsv
            csvTitle="Funding Events"
          >
            {({ row }) => (
              <>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="funding-entity-name">
                    {row.companyName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="time-period">
                    {toTitleCase(row.timePeriod)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING} data-qa="time-period-label">
                    {row.timePeriodLabel}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {transformDate(row.periodStartAt, DATE_FORMAT_MONTH_DAY_YEAR)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {transformDate(row.periodEndAt, DATE_FORMAT_MONTH_DAY_YEAR)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={TABLE_CELL_PADDING}>
                    {toTitleCase(row.status)}
                  </Typography>
                </TableCell>
              </>
            )}
          </BaseTable>
        ) : (
          <SkeletonTableLoader
            data-qa="skeleton-table-loader-funding-events"
            headerTitles={headers.map(column => `${column.label}`)}
            bodyRowsCount={10}
          />
        )}
      </Grid>
    </TcHubGuard>
  )
}
