import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { TcHubFundingEventDetailsPage } from "@/features/TCHub/AutoPay/FundingEvents/TcHubFundingEventDetailsPage"
import { TcHubEnrollmentDetailsPage } from "@/features/TCHub/Enrollment/details/TcHubEnrollmentDetailsPage"
import { TcHubEnrollmentPage } from "@/features/TCHub/Enrollment/TcHubEnrollmentPage"
import { RouteObject } from "react-router-dom"
import { TcHubGuard } from "./features/Auth/guards/TcHubGuard"
import { TcHubAccountDetailPage } from "./features/TCHub/AutoPay/Accounts/TcHubAccountDetailPage"
import { TcHubAccountsPage } from "./features/TCHub/AutoPay/Accounts/TcHubAccountsPage"
import { TcHubFundingEventsPage } from "./features/TCHub/AutoPay/FundingEvents/TcHubFundingEventsPage"
import { TchubAutoPayFundingReportsPage } from "./features/TCHub/AutoPay/FundingReports/TchubAutoPayFundingReportsPage"
import { TcHubIssuesPage } from "./features/TCHub/AutoPay/Issues/TcHubIssuesPage"
import { TcHubOnboardingRegistrationTrackingDetailPage } from "./features/TCHub/AutoPay/RegistrationTracking/TcHubOnboardingRegistrationTrackingDetailPage"
import { TcHubOnboardingRegistrationTrackingPage } from "./features/TCHub/AutoPay/RegistrationTracking/TcHubOnboardingRegistrationTrackingPage"
import { TcHubTransferPage } from "./features/TCHub/AutoPay/Transfer/TcHubTransferPage"
import { TcHubCarrierMetadataPage } from "./features/TCHub/Carriers/TcHubCarrierMetadataPage"
import { TcHubCarrierQuestionsPage } from "./features/TCHub/Carriers/TcHubCarrierQuestionsPage"
import { TcHubCompanyEdit } from "./features/TCHub/Companies/CompanyProfile/TcHubCompanyEdit"
import { TcHubCompanyProfilePage } from "./features/TCHub/Companies/CompanyProfile/TcHubCompanyProfilePage"
import { TcHubBusinessUnitPage } from "./features/TCHub/Companies/TcHubBusinessUnitPage"
import { TcHubCompaniesPage } from "./features/TCHub/Companies/TcHubCompaniesPage"
import { TcHubRecurringPremiumDetailPage } from "./features/TCHub/Compliance/TcHubRecurringPremiumDetailPage"
import { TcHubRecurringPremiumsPage } from "./features/TCHub/Compliance/TcHubRecurringPremiumsPage"
import { TcHubDashboardPage } from "./features/TCHub/Dashboard/TcHubDashboardPage"
import { TcHubLayout } from "./features/TCHub/layouts/TcHubLayout"
import { TcHubCreateJournalEntryPage } from "./features/TCHub/Ledger/JournalEntry/TcHubCreateJournalEntryPage"
import { TcHubReverseJournalEntryPage } from "./features/TCHub/Ledger/JournalEntry/TcHubReverseJournalEntryPage"
import { TcHubSiteSettingsPage } from "./features/TCHub/Settings/TcHubSiteSettingsPage"
import { TcHubUserDetailPage } from "./features/TCHub/Users/TcHubUserDetailPage"
import { TcHubUserEditPage } from "./features/TCHub/Users/TcHubUserEditPage"
import { TcHubUsersPage } from "./features/TCHub/Users/TcHubUsersPage"

export const tcHubRoutes: RouteObject = {
  // FUTURE: Remove guards from the routes page and move guards to their respective pages
  path: "/admin",
  element: (
    <AuthGuard>
      <TcHubGuard
        requiredPermissions={[
          "tc_hub_users",
          "tc_hub_companies",
          "tc_hub_autopay",
          "tc_hub_compliance",
          "tc_hub_site_settings",
        ]}
        matchType="some"
      >
        <TcHubLayout />
      </TcHubGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: "/admin/",
      element: (
        <TcHubGuard
          requiredPermissions={[
            "tc_hub_users",
            "tc_hub_companies",
            "tc_hub_autopay",
            "tc_hub_compliance",
            "tc_hub_site_settings",
          ]}
          matchType="some"
        >
          <TcHubDashboardPage />
        </TcHubGuard>
      ),
    },
    {
      path: "users",
      children: [
        {
          path: "",
          element: <TcHubUsersPage />,
        },
        {
          path: ":userId/:employmentId/:companyId",
          element: <TcHubUserDetailPage />,
        },
        {
          path: ":userId/:employmentId/:companyId/edit",
          element: <TcHubUserEditPage />,
        },
      ],
    },
    {
      path: "companies",
      children: [
        {
          path: "",
          element: <TcHubCompaniesPage />,
        },
        {
          path: ":companyId",
          element: <TcHubCompanyProfilePage />,
        },
        {
          path: ":companyId/business-units",
          element: <TcHubBusinessUnitPage />,
        },
        {
          path: ":companyId/edit",
          element: <TcHubCompanyEdit />,
        },
      ],
    },
    {
      path: "autopay/",
      children: [
        {
          path: "transfer",
          element: <TcHubTransferPage />,
        },
        {
          path: "accounts/",
          element: <TcHubAccountsPage />,
        },
        {
          path: "accounts/:fundingEntityId",
          element: <TcHubAccountDetailPage />,
        },
        {
          path: "registration-trackings",
          element: <TcHubOnboardingRegistrationTrackingPage />,
        },
        {
          path: "registration-trackings/:registrationTrackingId",
          element: <TcHubOnboardingRegistrationTrackingDetailPage />,
        },
        {
          path: "funding-events",
          element: <TcHubFundingEventsPage />,
        },
        {
          path: "funding-reports",
          element: <TchubAutoPayFundingReportsPage />,
        },
        {
          path: "funding-events/:companyId/:fundingEventId",
          element: <TcHubFundingEventDetailsPage />,
        },
        {
          path: "issues",
          element: <TcHubIssuesPage />,
        },
      ],
    },
    {
      path: "journal-entries",
      children: [
        {
          path: "create",
          element: (<TcHubCreateJournalEntryPage />),
        },
        {
          path: "reverse",
          element: (<TcHubReverseJournalEntryPage /> ),
        },
      ],
    },
    {
      path: "enrollments",
      children: [
        {
          path: "",
          element: (
            <TcHubGuard requiredPermissions={["tc_hub_compliance"]}>
              <TcHubEnrollmentPage />
            </TcHubGuard>
          ),
        },
        {
          path: ":enrollmentId",
          element: (
            <TcHubGuard requiredPermissions={["tc_hub_compliance"]}>
              <TcHubEnrollmentDetailsPage />
            </TcHubGuard>
          ),
        },
      ],
    },
    {
      path: "compliance/",
      children: [
        {
          path: "recurring-premiums",
          element: <TcHubRecurringPremiumsPage />,
        },
        {
          path: "recurring-premiums/:recurringPremiumId/:userId/:employmentId/:companyId",
          element: <TcHubRecurringPremiumDetailPage />,
        },
      ],
    },
    {
      path: "carriers/",
      children: [
        {
          path: "metadata",
          element: (
            <TcHubGuard requiredPermissions={["tc_hub_carriers"]}>
              <TcHubCarrierMetadataPage />
            </TcHubGuard>
          ),
        },
        {
          path: "questions",
          element: (
            <TcHubGuard requiredPermissions={["tc_hub_carriers"]}>
              <TcHubCarrierQuestionsPage />
            </TcHubGuard>
          ),
        },
      ],
    },
    {
      path: "site-settings",
      element: (
        <TcHubGuard requiredPermissions={["tc_hub_site_settings"]}>
          <TcHubSiteSettingsPage />
        </TcHubGuard>
      ),
    },
  ],
}
