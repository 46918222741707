import { Option } from "@/utils/types"
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material"

type SelectOption = Option<string, string>

export interface SelectMultiFieldProps<T extends SelectOption = SelectOption> {
  selectedValues: string[]
  fieldLabel: string
  readonly data: T[]
  name: string
  placeholder?: string
  required?: boolean
  dataQa?: string
  sx?: SxProps
  helperText?: string
  error?: boolean
  onChange?: (event: any) => void
  onBlur?: (event: any) => void
  renderValue?: (selected: string[]) => string
}

export const SelectMultiField = ({
  selectedValues,
  fieldLabel,
  data,
  name,
  placeholder,
  required,
  dataQa,
  sx = {},
  helperText,
  error,
  onChange,
  onBlur,
  renderValue,
}: SelectMultiFieldProps) => (
  <FormControl fullWidth error={error}>
    <InputLabel
      shrink
      id={dataQa}
      required={required}
      sx={{
        backgroundColor: "colors.white",
        px: 1,
      }}
    >
      {fieldLabel}
    </InputLabel>
    <Select
      labelId={dataQa}
      id={dataQa}
      data-qa={dataQa}
      multiple
      name={name}
      label={fieldLabel}
      required={required}
      value={selectedValues}
      sx={{
        "& .MuiSelect-select .notranslate::after": placeholder
          ? {
              content: `"${placeholder}"`,
              opacity: 0.42,
            }
          : {},
        textAlign: "start",
        ...sx,
      }}
      placeholder={placeholder}
      onChange={onChange}
      onClose={onBlur}
      renderValue={renderValue ?? (selected => selected.join(", "))}
      fullWidth
    >
      {data.map(({ value, label, ...optionProps }) => (
        <MenuItem key={value} value={value} data-qa={`${dataQa}-option-${value}`} {...optionProps}>
          <Checkbox checked={selectedValues.includes(value)} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
)
