import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { DashboardLayout } from "@/features/Dashboard/components/DashboardLayout"
import { usePermissions } from "@/services/permissionsService"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Grid, Tab, Typography } from "@mui/material"
import { SyntheticEvent } from "react"
import { Helmet } from "react-helmet-async"
import { useSearchParams } from "react-router-dom"
import { CompanyAutopay } from "./Tabs/CompanyAutopay"
import { CompanyBillingDetails } from "./Tabs/CompanyBillingDetails"
import { CompanyProfile } from "./Tabs/CompanyProfile"

const oAuthStateId = new URLSearchParams(window.location.search).get("oauth_state_id")

export const SettingsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const selectedTab = oAuthStateId ? "autopay" : (searchParams.get("tab") ?? "company-profile")

  const handleChange = (event: SyntheticEvent, newTab: string) => {
    setSearchParams({ tab: newTab, ...searchParams })
  }

  const { isLoading: isGettingCompanyLoading, data: currentCompany, error: getCompanyError } = useGetCompany(companyId)
  const hasBillingPermission = usePermissions("billing_payment_billing_dashboard")
  const showAutoPay = currentCompany?.companyInfo.autoPayAvailableOption

  return (
    <AuthGuard>
      <DashboardLayout>
        <Helmet title="Settings" />
        <Typography variant="h1" gutterBottom display="inline" data-qa="settings">
          Settings
        </Typography>
        <Grid item xs={12} my={5}>
          <Typography variant="h6" gutterBottom display="inline" data-qa="company-name">
            {isGettingCompanyLoading && !getCompanyError ? "Loading..." : currentCompany?.companyInfo?.companyName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                <Tab label="Company Profile" value="company-profile" data-qa="settings-company-profile-tab" />
                {hasBillingPermission && <Tab label="Billing" value="billing" data-qa="settings-company-billing-tab" />}
                {showAutoPay && <Tab label="AutoPay" value="autopay" data-qa="settings-company-autopay-tab" />}
              </TabList>
            </Box>
            <TabPanel value="company-profile">
              <CompanyProfile />
            </TabPanel>
            <TabPanel value="billing">
              <CompanyBillingDetails />
            </TabPanel>
            <TabPanel value="autopay">
              <CompanyAutopay oAuthStateFullHref={oAuthStateId ? window.location.href : ""} />
            </TabPanel>
          </TabContext>
        </Grid>
      </DashboardLayout>
    </AuthGuard>
  )
}
