import { DELETE, MEDICARE_MINIMUM_AGE, PATCH, POST } from "@/constants"
import { takeCommandPrimary } from "@/theme/palette"
import { createDateFromText, formatCents, toTitleCase } from "@/utils/formatting"
import { Cents, Uuid } from "@/utils/types"
import { ageIsAdult } from "@/utils/util"
import { capitalize, isString } from "lodash"
import moment from "moment"
import { useCallback } from "react"
import {
  BRONZE,
  BRONZE_COLOR,
  DEPENDENT,
  EXPANDED_BRONZE,
  EXPANDED_BRONZE_COLOR,
  GOLD,
  GOLD_COLOR,
  IN_NETWORK,
  OUT_OF_NETWORK,
  PAY_LATER,
  PAY_LATER_COLOR,
  PLAN_ENROLLMENT_TYPES_COLORS,
  PLAN_SEARCH_CATEGORIES_WITHOUT_CATASTROPHIC,
  PLATINUM,
  PLATINUM_COLOR,
  SELF,
  SILVER,
  SILVER_COLOR,
  SPOUSE,
  TAX_ADVANTAGED,
  TAX_ADVANTAGED_COLOR,
} from "./benefitsElectionConstants"
import { BenefitsElectionStore } from "./benefitsElectionStore"
import {
  AffordabilityCheckPayload,
  AllowanceEligibleApplicant,
  Carrier,
  DoctorPreference,
  DrugPreference,
  DrugResult,
  EligibleApplicant,
  EnrollmentType,
  FamilyRelationship,
  FilterPreferences,
  GetAllowancesPayload,
  HospitalPreference,
  ManageShoppingPersonPayload,
  PersonalInformation,
  PlanExtraInformationType,
  PlanFeature,
  PlanSearchCategory,
  PlanSearchPayload,
  PlanType,
  ProviderPreference,
  ProviderResult,
  ProviderType,
  ShoppingPerson,
  ShoppingPersonPayload,
} from "./benefitsElectionTypes"

export const isDateOfBirthValid = (date: Date) => !isNaN(date.getTime()) && date.getFullYear() > 1900

export const calculateAge = (date: Date): number => moment().diff(date, "years")

export const getShopperAge = (shopper: ShoppingPerson) =>
  calculateAge(new Date(shopper?.personalInformation?.dateOfBirth!))

type NameInfo = Pick<PersonalInformation, "firstName" | "lastName" | "preferredName">
export const getDisplayName = (info: NameInfo) => info.preferredName || `${info.firstName} ${info.lastName}`
export const useGetDisplayName = () => useCallback((info: NameInfo) => getDisplayName(info), [])

export const shopperIsAdult = (shopper: ShoppingPerson) => ageIsAdult(getShopperAge(shopper))

export const ageQualifiesForMedicare = (age: number): boolean => age >= MEDICARE_MINIMUM_AGE

export const formatStoredPersonalInformation = (personalInformation: PersonalInformation) => {
  const { dateOfBirth: dateOfBirthValue, gender, relationship = SELF } = personalInformation
  const dateOfBirth = isString(dateOfBirthValue) ? createDateFromText(dateOfBirthValue) : ""

  return {
    ...personalInformation,
    dateOfBirth,
    gender,
    relationship,
    isMedicareEligible: dateOfBirth && ageQualifiesForMedicare(calculateAge(dateOfBirth)),
  }
}

const createSpecialty = (specialty: string, subSpecialty: string) => `${specialty || ""} ${subSpecialty || ""}`.trim()

export const createProviderPreferences = <T extends ProviderType>(type: T, results: ProviderResult<T>[]) =>
  results.flatMap(({ npi, presentationName: name, specialty, subSpecialty = "", addresses }) =>
    addresses.map(
      (address): ProviderPreference<T> => ({
        id: `${npi}.${address.addressId}`,
        type,
        npi,
        name,
        specialty: createSpecialty(specialty, subSpecialty),
        addressId: address.addressId,
        address,
      })
    )
  )

export const createDrugPreferences = (drugs: DrugResult[]) =>
  drugs.map<DrugPreference>(({ rxCuiId, name, id, medId }) => ({
    rxCuiId,
    description: toTitleCase(name),
    id,
    medId,
  }))

export const formatProvider = (provider: DoctorPreference | HospitalPreference) => {
  const {
    name,
    specialty,
    address: { street1, street2 = "", city, state, zipCode },
  } = provider

  const street = `${street1} ${street2}`.trim()
  const label = specialty ? `${name} - ${specialty}` : name
  const location = `${street}, ${city}, ${state}, ${zipCode}`

  return `${label} - ${location}`
}

export const getPlanSearchApplicants = (applicants: EligibleApplicant[]) =>
  applicants.map(({ personalInformation: { dateOfBirth, relationship, isTobaccoUser: smoker } }) => ({
    age: calculateAge(new Date(dateOfBirth)),
    child: relationship === DEPENDENT,
    smoker,
  }))

/**
 * Builds the all plans filter preferences from the store values
 */
export const createAllPlansFilterPreferences = ({
  employee,
  getEligibleApplicants,
  currentShoppingSession,
}: BenefitsElectionStore): FilterPreferences => {
  const { zipCode, fipsCode } = employee?.personalInformation?.zipCode ?? {
    zipCode: "",
    fipsCode: "",
  }

  const planYear = currentShoppingSession.planYear
  const enrollmentDate = `${planYear}-01-01`
  const doctorPreferences: DoctorPreference[] = []
  const hospitalPreferences: HospitalPreference[] = []
  const drugPreferences: DrugPreference[] = []
  const carrierPreferences: Carrier[] = []
  const categories: PlanSearchCategory[] = []
  const enrollmentTypes: (EnrollmentType | PlanExtraInformationType)[] = []
  const isHsaEligible = ""
  const preferredPlanTypes: PlanType[] = []

  const allPlansFilterPreferences: FilterPreferences = {
    zipCode,
    fipsCode,
    enrollmentDate,
    doctorPreferences,
    hospitalPreferences,
    drugPreferences,
    carrierPreferences,
    categories,
    enrollmentTypes,
    isHsaEligible,
    preferredPlanTypes,
  }

  const applicants = getEligibleApplicants()

  allPlansFilterPreferences.applicants = getPlanSearchApplicants(applicants)

  allPlansFilterPreferences.checkRenewalPlanId = currentShoppingSession.healthBenefitElections[0].sourceHealthPlanId

  return allPlansFilterPreferences
}

/**
 * Builds the recommended filter preferences from the store values
 */
export const createRecommendedFilterPreferences = ({
  employee,
  getEligibleApplicants,
  currentShoppingSession,
}: BenefitsElectionStore): FilterPreferences => {
  const { zipCode, fipsCode } = employee?.personalInformation?.zipCode ?? {
    zipCode: "",
    fipsCode: "",
  }

  const applicants = getEligibleApplicants()
  const doctorPreferences = applicants.flatMap(applicant => applicant.doctors)
  const hospitalPreferences = applicants.flatMap(applicant => applicant.hospitals)
  const drugPreferences = applicants.flatMap(applicant => applicant.prescriptions)
  const carrierPreferences: Carrier[] = []
  const categories: PlanSearchCategory[] = []
  const enrollmentTypes: (EnrollmentType | PlanExtraInformationType)[] = []
  const isHsaEligible = ""
  const preferredPlanTypes: PlanType[] = []
  const planYear = currentShoppingSession.planYear
  const enrollmentDate = `${planYear}-01-01`

  return {
    zipCode,
    fipsCode,
    enrollmentDate,
    doctorPreferences,
    hospitalPreferences,
    drugPreferences,
    applicants: getPlanSearchApplicants(applicants),
    checkRenewalPlanId: currentShoppingSession.healthBenefitElections[0].sourceHealthPlanId,
    carrierPreferences,
    categories,
    enrollmentTypes,
    isHsaEligible,
    preferredPlanTypes,
  }
}

/**
 * Creates a payload from a Zustand filter object
 */
export const createPlanSearchFilterPayload = (filterPreferences: FilterPreferences): PlanSearchPayload => {
  const {
    zipCode = "",
    fipsCode = "",
    householdIncome,
    doctorPreferences = [],
    hospitalPreferences = [],
    drugPreferences = [],
    applicants = [],
    checkRenewalPlanId = "",
    enrollmentDate = "",
  } = filterPreferences

  const filterPayload: PlanSearchPayload = {
    zipCode,
    fipsCode,
    enrollmentDate,
  }

  if (householdIncome) {
    filterPayload.householdIncome = householdIncome
  }

  const doctorsPayload = doctorPreferences.map(provider => ({
    npi: provider.npi,
    addressId: provider.addressId,
  }))

  const hospitalsPayload = hospitalPreferences.map(provider => ({
    npi: provider.npi,
    addressId: provider.addressId,
  }))

  const providers = doctorsPayload.concat(hospitalsPayload)

  if (providers.length > 0) {
    filterPayload.providers = providers
  }

  if (drugPreferences.length > 0) {
    filterPayload.drugPackages = drugPreferences.map(drug => ({ medId: drug.medId }))
  }

  if (applicants.length > 0) {
    filterPayload.applicants = applicants
  }

  if (checkRenewalPlanId.length > 0) {
    filterPayload.checkRenewalPlanId = checkRenewalPlanId
  }

  filterPayload.categories = [...PLAN_SEARCH_CATEGORIES_WITHOUT_CATASTROPHIC]

  return filterPayload
}

export const createPlanFeatures = (featureAttributes: [Lowercase<string>, string, string, string][]) =>
  featureAttributes.map<PlanFeature>(([name, label, cost, extraData]) => ({
    name,
    label,
    cost,
    extraData,
  }))

export const getColorByMetal = (level: string | undefined) => {
  switch (level?.toUpperCase()) {
    case BRONZE:
      return BRONZE_COLOR
    case SILVER:
      return SILVER_COLOR
    case GOLD:
      return GOLD_COLOR
    case PLATINUM:
      return PLATINUM_COLOR
    case EXPANDED_BRONZE:
      return EXPANDED_BRONZE_COLOR
    default:
      return takeCommandPrimary.light
  }
}

export const validateApplicantForAllowance = (shopper: ShoppingPerson): shopper is AllowanceEligibleApplicant => {
  const { shoppingPersonId, personalInformation } = shopper
  const idValid = !!shoppingPersonId
  const personalInfoValid = Boolean(personalInformation && personalInformation.isEnrolledInMedicaid === false)

  return idValid && personalInfoValid
}

export const createGetAllowancesPayload = (employee: ShoppingPerson, familyMembers?: ShoppingPerson[]) => {
  const employeeAllowance = {
    // FUTURE: Remove this unsafe non-null assertion
    memberId: employee.shoppingPersonId!,
    age: calculateAge(employee.personalInformation?.dateOfBirth as Date),
    combinationsTag: "You",
  }

  const familyAllowances = familyMembers
    ?.filter(validateApplicantForAllowance)
    .map(({ shoppingPersonId, personalInformation = {} }) => {
      const { firstName, relationship, dateOfBirth } = personalInformation
      const age = calculateAge(dateOfBirth as Date)
      const combinationsTag = relationship === SPOUSE ? "Spouse" : capitalize(firstName)

      return {
        memberId: shoppingPersonId,
        age,
        combinationsTag,
        name: firstName!,
        relationship: relationship as FamilyRelationship,
      }
    })

  const allowancesPayload: GetAllowancesPayload = { employee: employeeAllowance }

  if (familyAllowances) {
    allowancesPayload.familyMembers = familyAllowances
  }

  return allowancesPayload
}

// Uses personal information values depending on PlanSearchApplicant
export const hasApplicantDataChanged = (
  editedPersonalInformation: PersonalInformation,
  originalPersonalInformation: PersonalInformation,
  isFamilyMember: boolean
) => {
  const dateOfBirthChanged = editedPersonalInformation.dateOfBirth !== originalPersonalInformation.dateOfBirth
  const zipCodeChanged = editedPersonalInformation.zipCode!.zipCode !== originalPersonalInformation.zipCode!.zipCode
  const tobaccoUserChanged = editedPersonalInformation.isTobaccoUser !== originalPersonalInformation.isTobaccoUser
  const relationshipChanged = editedPersonalInformation.relationship !== originalPersonalInformation.relationship

  return dateOfBirthChanged || tobaccoUserChanged || (isFamilyMember ? relationshipChanged : zipCodeChanged)
}

export const validateApplicants = (shoppingPersons: ShoppingPerson[], employeeFipsCode: string) =>
  shoppingPersons.filter((shopper: ShoppingPerson): shopper is EligibleApplicant => {
    const { shoppingPersonId, personalInformation } = shopper
    const isIdValid = !!shoppingPersonId

    const isPersonalInfoValid = Boolean(
      personalInformation &&
        personalInformation.isEnrolledInMedicaid === false &&
        !personalInformation.isMedicareEligible &&
        personalInformation.zipCode?.fipsCode === employeeFipsCode
    )

    return isIdValid && isPersonalInfoValid
  })

export const validateFamilyMembers = (shoppingPersons: ShoppingPerson[]) =>
  shoppingPersons.filter((shopper: ShoppingPerson): shopper is EligibleApplicant => {
    const { shoppingPersonId, personalInformation } = shopper
    const isIdValid = !!shoppingPersonId

    const isPersonalInfoValid = Boolean(personalInformation && personalInformation.isEnrolledInMedicaid === false)

    return isIdValid && isPersonalInfoValid
  })

export const createNewShoppingPersonPayload = (newMember: ShoppingPerson) => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    zipCode,
    isTobaccoUser,
    isMedicareEligible,
    isEnrolledInMedicaid,
    gender,
    relationship,
  } = newMember.personalInformation!

  const postMemberPayload: ManageShoppingPersonPayload = {
    method: POST,
    id: newMember.shoppingPersonId!,
    firstName,
    lastName,
    gender,
    dateOfBirth: dateOfBirth as string,
    zipCode: zipCode!.zipCode,
    county: zipCode!.countyName,
    state: zipCode!.state,
    fipsCode: zipCode!.fipsCode,
    isTobaccoUser,
    isMedicareEligible,
    isEnrolledInMedicaid,
    relationship,
    healthBenefitElections: [],
  }

  return postMemberPayload
}

export const createUpdatedShoppingPersonPayload = (editedMember: ShoppingPerson) => {
  const {
    firstName,
    lastName,
    preferredName,
    dateOfBirth,
    zipCode,
    isTobaccoUser,
    isMedicareEligible,
    isEnrolledInMedicaid,
    gender,
    relationship,
    phoneNumber,
  } = editedMember.personalInformation!

  const patchMemberPayload: ManageShoppingPersonPayload = {
    method: PATCH,
    id: editedMember.shoppingPersonId!,
    firstName,
    lastName,
    phoneNumber,
    dateOfBirth: dateOfBirth as string,
    zipCode: zipCode!.zipCode,
    county: zipCode!.countyName,
    state: zipCode!.state,
    fipsCode: zipCode!.fipsCode,
    isTobaccoUser,
    isMedicareEligible,
    isEnrolledInMedicaid,
  }

  if (gender) {
    patchMemberPayload.gender = gender
  }

  if (preferredName) {
    patchMemberPayload.preferredName = preferredName
  }

  if (relationship !== SELF) {
    patchMemberPayload.relationship = relationship
  }

  return patchMemberPayload
}

export const createPatchIncomePayload = (employeeId: Uuid, annualIncomeCents: Cents) => {
  const patchMemberPayload: ManageShoppingPersonPayload = {
    method: PATCH,
    id: employeeId,
    annualIncomeCents,
  }

  return patchMemberPayload
}

export const createDeleteShoppingPersonPayload = (deleteId: Uuid) => {
  const deleteMemberPayload: ManageShoppingPersonPayload = {
    method: DELETE,
    id: deleteId,
  }

  return deleteMemberPayload
}

const parseRawValue = (rawValue: string | undefined) => rawValue?.split("/") ?? [rawValue, rawValue]

const removeNetworkPrefix = (value: string | undefined, networkPrefix: string) =>
  value?.replace(networkPrefix, "").trim() ?? value

export const getInAndOutOfNetworkValues = (rawValue: string) => {
  const splitRawValue = parseRawValue(rawValue)
  const inNetworkValue = removeNetworkPrefix(splitRawValue[0], IN_NETWORK)
  const outOfNetworkValue = removeNetworkPrefix(splitRawValue[1], OUT_OF_NETWORK)

  return { inNetworkValue, outOfNetworkValue }
}

export const getPlanBalance = (allowanceCents: number, premiumAmountCents: number) => {
  const allowanceDifferenceCents = allowanceCents - premiumAmountCents
  const positive = allowanceDifferenceCents >= 0
  const balanceLabel = positive ? "Remaining allowance" : "My cost"
  const balanceValue = formatCents(Math.abs(allowanceDifferenceCents))
  const balanceLabelColor = positive ? takeCommandPrimary.main : "colors.lightErrorDark"

  return { balanceLabel, balanceValue, balanceLabelColor, positive } as const
}

export const getEnrollmentTypeColor = (enrollmentType: EnrollmentType | PlanExtraInformationType) => {
  if (enrollmentType === PAY_LATER) {
    return PAY_LATER_COLOR
  } else if (enrollmentType === TAX_ADVANTAGED) {
    return TAX_ADVANTAGED_COLOR
  } else {
    return PLAN_ENROLLMENT_TYPES_COLORS[enrollmentType]
  }
}

export type PlanBalance = ReturnType<typeof getPlanBalance>

export const createAffordabilityCheckPayload = ({
  employee,
  householdIncome,
  employeeOnlyAllowance,
  getEligibleApplicants,
  currentShoppingSession,
}: BenefitsElectionStore): AffordabilityCheckPayload => {
  const { zipCode, fipsCode } = employee?.personalInformation?.zipCode ?? {
    zipCode: "",
    fipsCode: "",
  }

  const affordabilityCheckPayload: AffordabilityCheckPayload = {
    zipCode,
    fipsCode,
    householdIncome,
    allowanceInCents: employeeOnlyAllowance?.amountCents!,
    planYear: currentShoppingSession.planYear,
  }

  affordabilityCheckPayload.applicants = getPlanSearchApplicants([employee as EligibleApplicant])

  return affordabilityCheckPayload
}

export const mapShoppingPersonPayloadToStore = (shoppingPersonPayload: ShoppingPersonPayload) => {
  const {
    id,
    drugPreferences,
    providerPreferences,
    firstName,
    lastName,
    preferredName,
    dateOfBirth,
    gender,
    isTobaccoUser,
    relationship,
    isEnrolledInMedicaid,
    zipCode,
    county: countyName,
    fipsCode,
    state,
    isMedicareEligible,
  } = shoppingPersonPayload

  const doctors: DoctorPreference[] = []
  const hospitals: HospitalPreference[] = []

  providerPreferences?.forEach(({ type, ...provider }) => {
    if (type === "individual") {
      doctors.push({ type, ...provider })
    } else {
      hospitals.push({ type, ...provider })
    }
  })

  const employee: ShoppingPerson = {
    shoppingPersonId: id,
    doctors,
    hospitals,
    prescriptions: drugPreferences ?? [],

    personalInformation: {
      firstName,
      lastName,
      preferredName,
      dateOfBirth,
      gender,
      isTobaccoUser,
      relationship,
      isEnrolledInMedicaid,
      isMedicareEligible,
      zipCode:
        zipCode && fipsCode && state
          ? {
              zipCode,
              countyName,
              fipsCode,
              state,
            }
          : null,
      acknowledgeTerms: false,
    },
  }

  return employee
}

export const planYearSuffix = (planYear: string | number | undefined) => (planYear ? ` for ${planYear}` : "")
