import { StyledCard } from "@/components/StyledCard"
import { PLAN_PREMIUM_LABEL } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { USING_TAX_CREDITS, WAIVE_COVERAGE_OPTIONS } from "@/features/BenefitsElection/benefitsElectionConstants"
import { planYearSuffix } from "@/features/BenefitsElection/benefitsElectionUtils"
import { EnrollmentBanner } from "@/features/BenefitsElection/components/EnrollmentBanner"
import { PlanLevelChip, PlanTypeChip } from "@/features/BenefitsElection/components/PlanComponents"
import { renderInsuranceType } from "@/features/BenefitsElection/pages/purchase/OtherPremiums"
import { colors, takeCommandPrimary } from "@/theme/palette"
import { createDataQa } from "@/utils/dataQa"
import { createDateFromText, formatCents, formatDate, toTitleCase } from "@/utils/formatting"
import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material"
import { useFeatureFlag } from "configcat-react"
import { isEmpty } from "lodash"
import { ReactNode, useContext, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useCheckQleAvailability, useGetEnrollmentTimePeriodsValue } from "../../dashboardService"
import { HealthInsuranceOverviewWidgetDataItem, WidgetData } from "../../dashboardTypes"
import { getColorByPremiumStatus } from "../../dashboardUtils"
import { DashboardContext } from "../DashboardContext"
import { WidgetLayout } from "../WidgetLayout"

const EMPTY_MONTHLY_PREMIUM = "$--"
const NOT_AVAILABLE_DATE = "N/A"
const EMPLOYEE = "EMPLOYEE"
const HEALTH_INSURANCE_ITEMS_KEY = "healthInsuranceOverviewItems"
const SUCCESS_STATUS = "SUCCESS"

interface HealthInsuranceOverviewWidgetProps {
  isLoading: boolean
  data: WidgetData
  status: string
}

const getCarrierLogoUrl = (data?: WidgetData) => (data && "carrierLogoUrl" in data ? data.carrierLogoUrl : "")
const getPlanName = (data?: WidgetData) => (data && "planName" in data ? data.planName : "")
const getPlanLevel = (data?: WidgetData) => (data && "planLevel" in data ? data.planLevel : "")
const getPlanType = (data?: WidgetData) => (data && "planType" in data ? data.planType : "")
const getInsuranceType = (data?: WidgetData) =>
  data && "insuranceType" in data ? renderInsuranceType(data.insuranceType) : ""
const getStartDate = (data?: WidgetData) => (data && "startDate" in data ? data.startDate : "")
const getEndDate = (data?: WidgetData) => (data && "endDate" in data ? data.endDate : "")

const getDisplayPremium = (data: WidgetData) =>
  data && "monthlyPremiumAmountCents" in data ? formatCents(data.monthlyPremiumAmountCents) : EMPTY_MONTHLY_PREMIUM

const getFamilyMembers = (data: WidgetData | undefined) => (data && "familyMembers" in data ? data.familyMembers : [])

const getHealthInsuranceItems = (data: WidgetData | undefined): HealthInsuranceOverviewWidgetDataItem[] =>
  data && "healthInsuranceOverviewItems" in data ? data.healthInsuranceOverviewItems : []

const getMissingHealthPlanInfo = (data: WidgetData | undefined) =>
  getPlanName(data) || getPlanLevel(data) || getPlanType(data) || getCarrierLogoUrl(data)

interface PremiumStatusChipProps {
  dataQa: string
  status: string
}
export const PremiumStatusChip = ({ dataQa, status }: PremiumStatusChipProps) => {
  const statusColor = getColorByPremiumStatus(status)

  return (
    <Chip
      data-qa={createDataQa(dataQa, "premium-status-chip")}
      sx={{
        color: "white",
        backgroundColor: statusColor,
      }}
      label={toTitleCase(status)}
    />
  )
}

const QleCard = () => (
  <StyledCard sx={{ backgroundColor: takeCommandPrimary[200], m: 0 }}>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6">Has your situation changed?</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          If you've recently experienced a qualifying life event, such as a change in your household or loss of health
          coverage, you may have options.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button component={Link} variant="contained" to="/qle" fullWidth>
          Explore Qualifying Life Events
        </Button>
      </Grid>
    </Grid>
  </StyledCard>
)

const EmptyWidgetContainer = ({ children }: { children: ReactNode }) => (
  <WidgetLayout sx={{ minHeight: "12rem" }}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
        height: "100%",
      }}
    >
      {children}
    </Box>
  </WidgetLayout>
)

const NoDataAvailableWidget = () => (
  <EmptyWidgetContainer>
    <Typography variant="body1" color="textSecondary">
      We cannot currently retrieve your health insurance information
    </Typography>
  </EmptyWidgetContainer>
)

interface ShoppingStatusWidgetProps {
  canEmployeeShop: boolean
  noItemsAvailable: boolean
  handleShop: () => void
  planYear: string | undefined
}

const ShoppingStatusWidget = ({ canEmployeeShop, noItemsAvailable, handleShop, planYear }: ShoppingStatusWidgetProps) => {
  const suffix = planYearSuffix(planYear)

  return (
    <EmptyWidgetContainer>
      {canEmployeeShop ? (
        <>
          <Typography variant="body1" color="textSecondary">
            No health plan selected{suffix}. Please select a health plan to continue.
          </Typography>
          <Button variant="contained" onClick={handleShop}>
            Shop
          </Button>
        </>
      ) : (
        noItemsAvailable && <Typography variant="body1" color="textSecondary">
          No health insurance on file{suffix}
        </Typography>
      )}
    </EmptyWidgetContainer>
  )
}

export const HealthInsuranceOverviewWidget = ({ isLoading, data, status }: HealthInsuranceOverviewWidgetProps) => {
  const navigate = useNavigate()

  const { user } = useAuth()
  const shoppingSession = user?.shoppingSession
  const employeeId = user?.company?.employeeId!

  const triggerParentRender = useContext(DashboardContext)

  const { data: enrollmentTimePeriods } = useGetEnrollmentTimePeriodsValue(employeeId)
  const { data: qleAvailabilityResponse, isSuccess: isSuccessQle } = useCheckQleAvailability(employeeId)
  const { value: qleEnabledValue } = useFeatureFlag("qleEnabled", false)
  const showQleCard = isSuccessQle && qleAvailabilityResponse.isQleAvailable && qleEnabledValue

  useEffect(() => {
    triggerParentRender()
  }, [showQleCard, triggerParentRender])

  const noDataAvailable =
    (isEmpty(data) && status !== SUCCESS_STATUS) || (!(HEALTH_INSURANCE_ITEMS_KEY in data) && status === SUCCESS_STATUS)

  if (noDataAvailable) {
    return <NoDataAvailableWidget />
  }

  const noItemsAvailable =
    status === SUCCESS_STATUS && HEALTH_INSURANCE_ITEMS_KEY in data && data.healthInsuranceOverviewItems.length === 0

  const timePeriod = enrollmentTimePeriods?.find(period => period.canShop)
  const canEmployeeShop = !!timePeriod?.canShop && !!shoppingSession
  const planYear = timePeriod?.planYear

  return (
    <>
      {canEmployeeShop && <EnrollmentBanner variant="outside-shopping-flow" planYear={planYear} />}
      {(canEmployeeShop || noItemsAvailable) && <ShoppingStatusWidget
        planYear={planYear}
        canEmployeeShop={!!canEmployeeShop}
        noItemsAvailable={noItemsAvailable}
        handleShop={() => {
          // SAFETY: We know shoppingSession is non-null in this function call because
          // the button is not rendered if shoppingSession is null (canEmployeeShop === false)
          navigate(`/benefits-election/${shoppingSession!.id}/welcome`)
        }}
      />}
      {getHealthInsuranceItems(data).map(item => {
        const familyMembers = getFamilyMembers(item)
        const startDate = getStartDate(item)
        const endDate = getEndDate(item)
        const employee = familyMembers.find(f => f.relationship === EMPLOYEE)
        const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : ""
        const missingHealthPlanInfo = getMissingHealthPlanInfo(item)
        const isPrimary = item.isPrimary
        const isWaived = item.isWaived
        const displayPremium = getDisplayPremium(item)

        if (isWaived) {
          return (
            <EmptyWidgetContainer key={item.benefitElectionId}>
              <Typography variant="body1" color="textSecondary">
                You've waived coverage for {item.planYear}.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                (Reason:{" "}
                {item.waiveReason === USING_TAX_CREDITS
                  ? "Accepted tax credits"
                  : WAIVE_COVERAGE_OPTIONS.find(option => item.waiveReason === option.value)?.label}
                )
              </Typography>
            </EmptyWidgetContainer>
          )
        }

        return (
          <WidgetLayout
            key={item.benefitElectionId}
            isLoading={isLoading}
            data-qa={createDataQa(isPrimary ? "primary" : "secondary", "card")}
          >
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <Typography variant="h2small" data-qa="health-insurance-title">
                  Health insurance
                </Typography>
              </Grid>
              {missingHealthPlanInfo && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      minHeight: "5.5rem",
                      maxHeight: "5.5rem",
                    }}
                  >
                    <img
                      src={getCarrierLogoUrl(item)}
                      alt={`${getPlanName(item)} logo`}
                      style={{
                        width: "5.625rem",
                        objectFit: "contain",
                        minHeight: "5.5rem",
                        maxHeight: "5.5rem",
                      }}
                      data-qa={createDataQa("card", "logo")}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h2small" data-qa={createDataQa(isPrimary ? "plan-name" : "insurance-type")}>
                  {isPrimary ? getPlanName(item) : getInsuranceType(item)}
                </Typography>
              </Grid>

              {missingHealthPlanInfo && (
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", gap: 2, py: 2 }}>
                  <PlanLevelChip dataQa={createDataQa("plan", "level", "chip")} level={getPlanLevel(item)} />
                  <PlanTypeChip dataQa={createDataQa("plan", "type", "chip")} planType={getPlanType(item)} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant="body1bold" data-qa={createDataQa("plan", "premium", "label")}>
                  {PLAN_PREMIUM_LABEL}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  display="inline"
                  variant="h5"
                  color={takeCommandPrimary.main}
                  data-qa={createDataQa("plan", "monthly", "premium")}
                >
                  {displayPremium}
                </Typography>
                <Typography display="inline" variant="body1" color={takeCommandPrimary.main}>
                  /mo
                </Typography>
              </Grid>

              {familyMembers.length > 0 && (
                <Grid container item xs={12} py={4}>
                  <Divider style={{ width: "100%" }} />
                  <Grid item pt={4}>
                    <Typography variant="body2" data-qa={createDataQa("employee", "name")}>
                      <strong>Covered members:</strong> {employeeName}
                    </Typography>
                  </Grid>
                  {familyMembers
                    .filter(({ relationship }) => relationship !== EMPLOYEE)
                    .map(({ firstName, lastName }) => {
                      const memberName = `${firstName} ${lastName}`

                      return (
                        <Grid item xs={12} py={1} key={memberName}>
                          <Typography variant="body2" data-qa={createDataQa("family", "member", "name")}>
                            {memberName}
                          </Typography>
                        </Grid>
                      )
                    })}
                </Grid>
              )}
              <Divider style={{ width: "100%" }} />
              <Grid
                container
                item
                xs={12}
                py={4}
                mb="auto"
                pb={0}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid container item xs={6} direction="column">
                  <Grid item>
                    <Typography variant="body1bold" data-qa={createDataQa("start", "date", "label")}>
                      Start date
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="body1" color={colors.lightGrayText} data-qa={createDataQa("start", "date")}>
                      {startDate ? formatDate(createDateFromText(getStartDate(item))) : NOT_AVAILABLE_DATE}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={6} direction="column" textAlign="right">
                  <Grid item pr={3}>
                    <Typography variant="body1bold" data-qa={createDataQa("end", "date", "label")}>
                      End date
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="body1" color={colors.lightGrayText} data-qa={createDataQa("end", "date")}>
                      {endDate ? formatDate(createDateFromText(getEndDate(item))) : NOT_AVAILABLE_DATE}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </WidgetLayout>
        )
      })}
      <WidgetLayout sx={{ p: 0, m: 0 }} isLoading={isLoading}>
        {showQleCard && <QleCard />}
      </WidgetLayout>
    </>
  )
}
