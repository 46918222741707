import { ModalConfig } from "@/components/ConfirmationModal"
import { OTHER } from "@/features/BenefitsElection/benefitsElectionConstants"
import { getShoppingSessions } from "@/features/BenefitsElection/benefitsElectionEndpoints"
import { WaiveCoveragePayload } from "@/features/BenefitsElection/benefitsElectionTypes"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { useSnackbar } from "notistack"
import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from "react"
import { useNotifications } from "../../../services/notificationService"
import { sendSignUpLinkPersonId } from "../peopleManagementEndpoints"
import { confirmReinstateModal, confirmTerminationModal, confirmWaiveModal, failedModal } from "../peopleModals"
import { useReinstatePersonMutation, useTerminatePerson, useWaivePersonByEmploymentId } from "../peopleService"
import { PEOPLE_INACTIVE_STATUSES } from "../peopleConstants"
import { PeopleStatus } from "../peopleTypes"

interface PeopleOptionsDropdownProps {
  personId: string
  employmentId: string
  status: PeopleStatus
  hideSendSignUpLink?: boolean
  hideCopySignUpLink?: boolean
  hideWaive?: boolean
  companyId?: string
  setModal: (modalState: ModalConfig) => void
  setIsModalSubmitting: (isModalSubmitting: boolean) => void
  setOpen: Dispatch<SetStateAction<boolean>>
  setPersonProfileId: Dispatch<SetStateAction<string>>
}

interface StatusActionLabelProps {
  archived: boolean
}

const StatusActionLabel = ({ archived }: StatusActionLabelProps) => {
  if (archived) return <>Reinstate</>

  return <>Terminate</>
}

const SIGN_UP_LINK_SENT_SUCCESS = "Invite email sent successfully"
const SIGN_UP_LINK_SENT_ERROR = "User has already signed up"
const SIGNUP_LINK_RETRIEVE_ERROR = "Error retrieving signup link"
const SIGN_UP_LINK_COPY_ERROR = "Error copying signup link"
const SIGN_UP_LINK_COPY_SUCCESS = "Signup link copied successfully"

// FUTURE: use conditional rendered component instead of the setModal pattern
export const PeopleOptionsDropdown = ({
  personId,
  employmentId,
  status,
  hideSendSignUpLink = false,
  hideCopySignUpLink = false,
  hideWaive = false,
  companyId = "",
  setModal,
  setIsModalSubmitting,
  setOpen,
  setPersonProfileId,
}: PeopleOptionsDropdownProps) => {
  const { notify } = useNotifications("email-information")
  const { mutateAsync: reinstatePerson, isPending: isReinstatingPerson } = useReinstatePersonMutation(companyId)
  const { mutateAsync: terminatePerson, isPending: isTerminatingPerson } = useTerminatePerson(companyId)
  const { mutateAsync: waivePerson, isPending: isWaivingPerson } = useWaivePersonByEmploymentId(companyId)
  const [showPeopleOptions, setShowPeopleOptions] = useState<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const [signupLink, setSignupLink] = useState(null)

  const isArchived = status in PEOPLE_INACTIVE_STATUSES
  const waiveCoverageInitialValues: WaiveCoveragePayload = {
    reason: OTHER,
    otherReason: "waived by admin",
  }

  useEffect(
    () => {
      const isSubmitting = isReinstatingPerson || isTerminatingPerson || isWaivingPerson

      setIsModalSubmitting(isSubmitting)
    },
    // FUTURE: Resolve this violation of the Rules of Hooks and remove this eslint-disable directive
    // More info: https://react.dev/reference/rules/rules-of-hooks
    // This has since been promoted to a hard error
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReinstatingPerson, isTerminatingPerson, isWaivingPerson]
  )

  const clearModal = () => {
    setModal(null)
  }

  const handleSendSignUpLink = async () => {
    try {
      await sendSignUpLinkPersonId(companyId, personId)
      notify(SIGN_UP_LINK_SENT_SUCCESS, "success")
    } catch (error) {
      notify(SIGN_UP_LINK_SENT_ERROR, "error")
    }
  }

  const handleCopySignUpLink = async () => {
    try {
      const signUpLink = await sendSignUpLinkPersonId(companyId, personId)
      setSignupLink(signUpLink)
    } catch (error) {
      notify(SIGNUP_LINK_RETRIEVE_ERROR, "error")
    }
  }

  useEffect(() => {
    const copyToClipboard = async (textToCopy: string) => {
      try {
        if (textToCopy !== null) {
          await navigator.clipboard.writeText(textToCopy)
          notify(SIGN_UP_LINK_COPY_SUCCESS, "success")
        }
      } catch (error) {
        notify(SIGN_UP_LINK_COPY_ERROR, "error")
      }
    }

    if (signupLink !== null) {
      copyToClipboard(signupLink)
    }
  }, [signupLink, notify])

  const failed = {
    ...failedModal,
    onConfirm: clearModal,
  }

  const confirmTermination = {
    ...confirmTerminationModal,
    onConfirm: async () => {
      try {
        let snackbarMessage = null
        let dataQa = null

        await terminatePerson(personId)
        snackbarMessage = "Person terminated successfully"
        dataQa = "terminate-person-successful"

        // FUTURE: Refactor to use the notifications service and remove eslint-disable directive
        // eslint-disable-next-line no-restricted-syntax
        enqueueSnackbar(snackbarMessage, {
          variant: "simpleNotification",
          selection: "success",
          dataQa,
        })

        clearModal()
      } catch (e) {
        setModal(failed)
      }
    },
  }

  const confirmReinstate = {
    ...confirmReinstateModal,
    onConfirm: async () => {
      try {
        await reinstatePerson(personId)
        // FUTURE: Refactor to use the notifications service and remove eslint-disable directive
        // eslint-disable-next-line no-restricted-syntax
        enqueueSnackbar("Person reinstated successfully", {
          variant: "simpleNotification",
          selection: "success",
          dataQa: "reinstate-person-successful",
        })
        clearModal()
      } catch (e) {
        setModal(failed)
      }
    },
  }

  const confirmWaive = {
    ...confirmWaiveModal,
    onConfirm: async () => {
      try {
        const shoppingSessions = await getShoppingSessions(employmentId, "", false)
        const shoppingSessionsResult = shoppingSessions ?? []

        await waivePerson({ shoppingSessionId: shoppingSessionsResult[0].id, ...waiveCoverageInitialValues })
        // FUTURE: Refactor to use the notifications service and remove eslint-disable directive
        // eslint-disable-next-line no-restricted-syntax
        enqueueSnackbar("Person waived successfully", {
          variant: "simpleNotification",
          selection: "success",
          dataQa: "waive-person-successful",
        })
        clearModal()
      } catch (e) {
        setModal(failed)
      }
    },
  }

  const showMenu = (event: SyntheticEvent) => {
    setShowPeopleOptions(event.currentTarget)
  }

  const closeMenu = () => {
    setShowPeopleOptions(null)
  }

  return (
    <div data-qa={`icon-more-actions-${personId}`}>
      <Tooltip title="Options">
        <IconButton
          data-qa={`dropdown-button-${personId}`}
          aria-owns={showPeopleOptions ? "menu-people-options" : undefined}
          aria-haspopup="true"
          size="large"
          onClick={showMenu}
          sx={{ width: "2.5rem", height: "2.5rem", borderRadius: "50%" }}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        data-qa="menu-people-options"
        id="menu-people-options"
        anchorEl={showPeopleOptions}
        open={Boolean(showPeopleOptions)}
        onClose={closeMenu}
      >
        <MenuItem
          className="tch-menu-item"
          onClick={() => {
            setPersonProfileId(personId)
            setOpen(true)
            closeMenu()
          }}
        >
          View
        </MenuItem>
        <MenuItem
          className="tch-menu-item"
          data-qa={isArchived ? "reinstate-option" : "terminate-option"}
          onClick={() => {
            closeMenu()
            // FUTURE: Need to add logic to open termination modal for "archiving"/removing
            // a benefits eligible person. If person is benefits eligible, open the
            // termination modal. Otherwise, open the archive confirmation modal.
            setModal(isArchived ? confirmReinstate : confirmTermination)
          }}
        >
          <StatusActionLabel archived={isArchived} />
        </MenuItem>
        {!hideSendSignUpLink && (
          <MenuItem
            className="tch-menu-item"
            data-qa="send-signup-link"
            onClick={() => {
              closeMenu()
              handleSendSignUpLink()
            }}
          >
            Send Sign-Up Link
          </MenuItem>
        )}
        {!hideSendSignUpLink && !hideCopySignUpLink && (
          <MenuItem
            className="tch-menu-item"
            data-qa="copy-signup-link"
            onClick={() => {
              closeMenu()
              handleCopySignUpLink()
            }}
          >
            Copy invite link to clipboard
          </MenuItem>
        )}

        {!hideWaive && (
          <MenuItem
            data-qa="waive-option"
            className="tch-menu-item"
            onClick={() => {
              closeMenu()
              setModal(confirmWaive)
            }}
          >
            Waive
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
