import { ChipsList } from "@/components/Chips"
import { ConfirmationModal } from "@/components/ConfirmationModal"
import { RadioGroupCard } from "@/components/RadioGroupCard"
import { YES_NO_OPTIONS } from "@/constants"
import { useAuth } from "@/features/Auth/useAuth"
import { createDataQa } from "@/utils/dataQa"
import { toTitleCase } from "@/utils/formatting"
import { PickRequired, Uuid, WrapperProps, YesOrNo } from "@/utils/types"
import { HelpOutline } from "@mui/icons-material"
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, Tooltip, Typography } from "@mui/material"
import { sortBy } from "lodash"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import {
  PAY_LATER,
  PLAN_ENROLLMENT_TYPES,
  PLAN_SEARCH_CATEGORIES_WITHOUT_CATASTROPHIC,
  PLAN_TYPES,
} from "../../benefitsElectionConstants"
import { useIsCompanyAutoPay, useMyInfo } from "../../benefitsElectionService"
import {
  Carrier,
  DoctorPreference,
  DrugId,
  DrugPreference,
  EnrollmentType,
  FilterPreferences,
  HospitalPreference,
  PlanExtraInformationType,
  PlanSearchCategory,
  PlanType,
  ProviderPreferenceId,
} from "../../benefitsElectionTypes"
import { formatProvider } from "../../benefitsElectionUtils"
import { DoctorsSearchField } from "../myInfo/Doctors"
import { HospitalsSearchField } from "../myInfo/Hospitals"
import { DrugsSearchField } from "../myInfo/Prescriptions"

export const baseDataQa = "plan-filter"

interface NetworkSectionButtonProps {
  label: string
  name: string
  selectionsLength: number
  handleClick: () => void
}

const NetworkSectionButton = ({ label, name, selectionsLength, handleClick }: NetworkSectionButtonProps) => (
  <Button
    variant="outlined"
    fullWidth
    data-qa={`${baseDataQa}-edit-${name}-button`}
    sx={{
      color: "colors.darkBody",
    }}
    onClick={handleClick}
  >
    {`${selectionsLength > 0 ? "Edit" : "Add"} ${label}`}
  </Button>
)

interface NetworkSectionProps {
  doctorSelections: DoctorPreference[]
  hospitalSelections: HospitalPreference[]
  prescriptionSelections: DrugPreference[]
  handleClick: () => void
}

const NetworkSection = ({
  doctorSelections,
  hospitalSelections,
  prescriptionSelections,
  handleClick,
}: NetworkSectionProps) => (
  <Grid container item spacing={4}>
    <Grid item xs={12} data-qa={`${baseDataQa}-doctors-label`} mt={4}>
      <Typography variant="h6">Search for your preferred doctor</Typography>
    </Grid>
    <Grid item xs={12}>
      <Card sx={{ bgcolor: "colors.oldLace" }} data-qa={`${baseDataQa}-doctors-help-card`}>
        <CardContent>
          <Typography>
            The Doctor Search tool is not always up to date as doctors sometimes do not update whether they’re in or out
            off network for a health plan. Make sure to contact your doctor directly to ensure they’re in-network for
            your chosen health plan before purchasing.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h6">Doctors or specialists</Typography>
    </Grid>
    <Grid item xs={12}>
      <NetworkSectionButton
        name="doctors"
        selectionsLength={doctorSelections.length}
        label="your doctors"
        handleClick={handleClick}
      />
    </Grid>
    <Grid item xs={12}>
      <ChipsList
        selections={doctorSelections}
        formatSelection={doctor => ({
          key: doctor.id,
          label: formatProvider(doctor),
        })}
      />
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h6">Hospitals or medical facilities</Typography>
    </Grid>
    <Grid item xs={12}>
      <NetworkSectionButton
        name="hospitals"
        selectionsLength={hospitalSelections.length}
        label="your hospitals"
        handleClick={handleClick}
      />
    </Grid>
    <Grid item xs={12}>
      <ChipsList
        selections={hospitalSelections}
        formatSelection={hospital => ({
          key: hospital.id,
          label: formatProvider(hospital),
        })}
      />
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h6">Medication and prescriptions</Typography>
    </Grid>
    <Grid item xs={12}>
      <NetworkSectionButton
        name="prescriptions"
        selectionsLength={prescriptionSelections.length}
        label="your prescriptions"
        handleClick={handleClick}
      />
    </Grid>
    <Grid item xs={12}>
      <ChipsList
        selections={prescriptionSelections}
        formatSelection={({ description, id }) => ({
          key: id,
          label: description,
        })}
      />
    </Grid>
  </Grid>
)

interface NetworkFilterProps {
  showModal: boolean
  onClose: () => void
  doctorPreferences: DoctorPreference[]
  hospitalPreferences: HospitalPreference[]
  drugPreferences: DrugPreference[]
  handlePreferencesChange: (
    doctorSelections: FilterPreferences["doctorPreferences"],
    hospitalSelections: FilterPreferences["hospitalPreferences"],
    prescriptionSelections: FilterPreferences["drugPreferences"]
  ) => void
}

const NetworkFilter = ({
  drugPreferences,
  hospitalPreferences,
  doctorPreferences,
  showModal,
  handlePreferencesChange,
  onClose,
}: NetworkFilterProps) => {
  const { planSearchParams } = useMyInfo()

  const [doctorSelections, setDoctorSelections] = useState<DoctorPreference[]>(doctorPreferences)
  const [hospitalSelections, setHospitalSelections] = useState<HospitalPreference[]>(hospitalPreferences)
  const [prescriptionSelections, setPrescriptionSelections] = useState<DrugPreference[]>(drugPreferences)

  const addDoctorSelection = (selection: DoctorPreference) => {
    setDoctorSelections(previousSelections =>
      previousSelections.some(doctor => doctor.id === selection.id)
        ? previousSelections
        : [...previousSelections, selection]
    )
  }

  const removeDoctorSelection = (removeId: ProviderPreferenceId) => {
    setDoctorSelections(previousSelections =>
      previousSelections.filter(selectedDoctor => selectedDoctor.id !== removeId)
    )
  }

  const addHospitalSelection = (selection: HospitalPreference) => {
    setHospitalSelections(previousSelections =>
      previousSelections.some(hospital => hospital.id === selection.id)
        ? previousSelections
        : [...previousSelections, selection]
    )
  }

  const removeHospitalSelection = (removeId: ProviderPreferenceId) => {
    setHospitalSelections(previousSelections =>
      previousSelections.filter(selectedHospital => selectedHospital.id !== removeId)
    )
  }

  const addPrescriptionSelection = (selection: DrugPreference) => {
    setPrescriptionSelections(previousSelections =>
      previousSelections.some(drug => drug.id === selection.id)
        ? previousSelections
        : [...previousSelections, selection]
    )
  }

  const removePrescriptionSelection = (removeId: DrugId) => {
    setPrescriptionSelections(previousSelections =>
      previousSelections.filter(selectedDrug => selectedDrug.id !== removeId)
    )
  }
  return (
    <ConfirmationModal
      isOpen={showModal}
      title="Check Your Coverage"
      onConfirm={() => {
        handlePreferencesChange(doctorSelections, hospitalSelections, prescriptionSelections)
        onClose()
      }}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: "37rem" } }}
      actionLabel="Apply to plans"
    >
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography>
            Looking for a specific doctor, hospital, or prescription to see if they're covered by your plan? Use our
            search bar to quickly find in-network providers and medications. Simply enter the name of your doctor,
            hospital, or prescription drug, and we’ll show you the plans that cover them.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DoctorsSearchField
            title={undefined}
            selections={doctorSelections}
            handleSelection={addDoctorSelection}
            handleDelete={removeDoctorSelection}
            {...planSearchParams}
          />
        </Grid>
        <Grid item xs={12}>
          <HospitalsSearchField
            selections={hospitalSelections}
            handleSelection={addHospitalSelection}
            handleDelete={removeHospitalSelection}
            title={undefined}
            {...planSearchParams}
          />
        </Grid>
        <Grid item xs={12}>
          <DrugsSearchField
            selections={prescriptionSelections}
            handleSelection={addPrescriptionSelection}
            handleDelete={removePrescriptionSelection}
            title={undefined}
            {...planSearchParams}
          />
        </Grid>
      </Grid>
    </ConfirmationModal>
  )
}

interface TextExplanationProps {
  title: string
  description: string
  sectionName: string
}
const TextExplanation = ({ title, description, sectionName }: TextExplanationProps) => (
  <Typography data-qa={createDataQa(baseDataQa, sectionName, "explanation")}>
    <Typography component="span" variant="body1bold">
      {title}:
    </Typography>{" "}
    {description}
  </Typography>
)

interface PlanTypeFilterProps {
  selectedPlanTypes: PlanType[]
  selectPlanType: (planType: PlanType) => void
  unselectPlanType: (planType: PlanType) => void
}

interface FilterExplanationProps extends PickRequired<WrapperProps, "children"> {
  title: string
}

const FilterExplanation = ({ children, title, "data-qa": dataQa }: FilterExplanationProps) => (
  <Grid container alignContent="center" justifyContent="space-between">
    <Grid item>
      <Typography variant="h5" display="inline" data-qa={createDataQa(dataQa, "label")}>
        {title}
      </Typography>
    </Grid>
    <Grid item>
      <Tooltip slotProps={{ tooltip: { sx: { maxHeight: "500px", overflowY: "scroll" } } }} title={children}>
        <HelpOutline />
      </Tooltip>
    </Grid>
  </Grid>
)

const PlanTypeFilter = ({ selectedPlanTypes, selectPlanType, unselectPlanType }: PlanTypeFilterProps) => (
  <>
    <Grid item xs={12} mt={8} justifyContent="space-between">
      <FilterExplanation title="By plan type" data-qa={createDataQa(baseDataQa, "by-plan-type")}>
        <TextExplanation
          sectionName="plan-type"
          title="EPO"
          description="Hybrid of PPO and HMO. Lower than PPO premiums and a referral is not required to see a specialist."
        />
        <TextExplanation
          sectionName="plan-type"
          title="POS"
          description="Hybrid of PPO and HMO. Referrals are required. Access to out-of-network care if needed (at a higher cost)."
        />
        <TextExplanation
          sectionName="plan-type"
          title="HMO"
          description="You have to get a PCP (Primary Care Provider) referral before seeing a specialist. Lower monthly premiums."
        />
        <TextExplanation
          sectionName="plan-type"
          title="PPO"
          description="Most flexible, yet most expensive network type. Do not need a referral to see a specialist. Option to see doctor outside preferred network."
        />
      </FilterExplanation>
    </Grid>
    <Grid item sx={{ mt: 4, display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
      {PLAN_TYPES.map(planType => {
        const dataQa = createDataQa(baseDataQa, planType, "item")

        return (
          <Grid key={planType} item xs={12} sx={{ mt: 0 }}>
            <FormControlLabel
              control={
                <Checkbox checked={selectedPlanTypes.includes(planType)} data-qa={createDataQa(dataQa, "checkbox")} />
              }
              label={planType}
              onChange={(_, checked) => {
                if (checked) {
                  selectPlanType(planType)
                } else {
                  unselectPlanType(planType)
                }
              }}
              data-qa={dataQa}
            />
          </Grid>
        )
      })}
    </Grid>
  </>
)

interface CarrierFilterProps {
  availableCarriers: Carrier[]
  carrierSelections: Carrier[]
  addCarrierSelection: (selection: Carrier) => void
  removeCarrierSelection: (removeId: string) => void
}

const CarrierFilter = ({
  availableCarriers,
  carrierSelections,
  addCarrierSelection,
  removeCarrierSelection,
}: CarrierFilterProps) => {
  const selectedIds = carrierSelections.map(s => s.id)
  const sortedCarriers = useMemo(() => availableCarriers && sortBy(availableCarriers, "name"), [availableCarriers])

  return (
    <>
      <Grid item sx={{ mt: 8, mb: 4 }} xs={12}>
        <Typography variant="h5" data-qa={`${baseDataQa}-by-carrier-label`}>
          By carrier
        </Typography>
      </Grid>
      {sortedCarriers?.map(c => {
        const dataQa = createDataQa(baseDataQa, "carrier-item")

        return (
          <Grid key={c.id} item xs={12} sx={{ mt: 0 }}>
            <FormControlLabel
              control={<Checkbox checked={selectedIds.includes(c.id)} data-qa={createDataQa(dataQa, "checkbox")} />}
              label={c.name}
              onChange={(_, checked) => {
                if (checked) {
                  addCarrierSelection(c)
                } else {
                  removeCarrierSelection(c.id)
                }
              }}
              data-qa={dataQa}
            />
          </Grid>
        )
      })}
    </>
  )
}

const metalTierExplanations = [
  {
    title: "Platinum",
    description: "covers 90% on average of your medical costs; you pay 10%",
  },
  {
    title: "Gold",
    description: "covers 80% on average of your medical costs; you pay 20%",
  },
  {
    title: "Silver",
    description: "covers 70% on average of your medical costs; you pay 30%",
  },
  {
    title: "Bronze",
    description: "covers 60% on average of your medical costs; you pay 40%",
  },
  {
    title: "Expanded Bronze",
    description:
      "Catastrophic policies pay after you have reached a very high deductible ($8,700 in 2022). Expanded Bronze plans must also cover the first three primary care visits and preventive care for free, even if you have not yet met your deductible",
  },
] as const

interface MetalTierFilterProps {
  selectedMetalTiers: PlanSearchCategory[]
  selectMetalTier: (metalTier: PlanSearchCategory) => void
  unselectMetalTier: (metalTier: PlanSearchCategory) => void
}

const MetalTierFilter = ({ selectedMetalTiers, selectMetalTier, unselectMetalTier }: MetalTierFilterProps) => (
  <>
    <Grid item sx={{ mt: 8, mb: 4 }} xs={12}>
      <FilterExplanation title="By metal tier" data-qa={createDataQa(baseDataQa, "by-metal-tier")}>
        {metalTierExplanations.map(({ title, description }) => (
          <TextExplanation key={title} title={title} description={description} sectionName="metal-tier" />
        ))}
      </FilterExplanation>
    </Grid>

    {PLAN_SEARCH_CATEGORIES_WITHOUT_CATASTROPHIC.map(metalTier => {
      const dataQa = createDataQa(baseDataQa, metalTier, "item")

      return (
        <Grid key={metalTier} item xs={12} sx={{ mt: 0 }}>
          <FormControlLabel
            control={
              <Checkbox checked={selectedMetalTiers.includes(metalTier)} data-qa={createDataQa(dataQa, "checkbox")} />
            }
            label={toTitleCase(metalTier)}
            onChange={(_, checked) => {
              if (checked) {
                selectMetalTier(metalTier)
              } else {
                unselectMetalTier(metalTier)
              }
            }}
            data-qa={dataQa}
          />
        </Grid>
      )
    })}
  </>
)

interface EnrollmentTypesFilterProps {
  isAutoPay: boolean
  selectedEnrollmentTypes: (EnrollmentType | PlanExtraInformationType)[]
  selectEnrollmentType: (enrollmentType: EnrollmentType | PlanExtraInformationType) => void
  unselectEnrollmentType: (enrollmentType: EnrollmentType | PlanExtraInformationType) => void
}

const EnrollmentTypesFilter = ({
  isAutoPay,
  selectedEnrollmentTypes,
  selectEnrollmentType,
  unselectEnrollmentType,
}: EnrollmentTypesFilterProps) => (
  <>
    <Grid item sx={{ mt: 10, mb: 4 }} xs={12}>
      <FilterExplanation title="Enrollment types" data-qa={createDataQa(baseDataQa, "enrollment-types")}>
        <TextExplanation
          sectionName="enrollment"
          title="Easy Enroll"
          description="The enrollment process is quick & easy! You choose your plan, and Take Command handles the rest—submitting your application and payment and confirming enrollment. Take Command can also assist you with any coverage issues with the insurance company."
        />
        <TextExplanation
          sectionName="enrollment"
          title="Easy Enroll - Extra Step"
          description="You'll need to take additional action to finalize your enrollment, such as setting up payment information or providing a signature. Don't worry, Take Command will submit your application and support you in working with the insurance company."
        />
        <TextExplanation
          sectionName="enrollment"
          title="Self Enroll"
          description="You choose your plan and enroll in health insurance on your own, typically on an insurance company's website or the exchange. Remember to provide your proof of coverage in the HRA Hub platform to participate in your company's HRA."
        />
        {!isAutoPay && (
          <TextExplanation
            sectionName="enrollment"
            title="Pay Later"
            description="Payment is not required at the time of enrollment, so you’ll receive a bill to pay later. These plans are also Easy Enroll, so Take Command will submit your application and can assist you with the insurance company if needed."
          />
        )}
      </FilterExplanation>
    </Grid>

    {(isAutoPay ? ([...PLAN_ENROLLMENT_TYPES, PAY_LATER] as const) : PLAN_ENROLLMENT_TYPES).map(enrollmentType => {
      const dataQa = createDataQa(baseDataQa, enrollmentType, "item")

      return (
        <Grid key={enrollmentType} item xs={12} sx={{ mt: 0 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedEnrollmentTypes.includes(enrollmentType)}
                data-qa={createDataQa(dataQa, "checkbox")}
              />
            }
            label={toTitleCase(enrollmentType)}
            onChange={(_, checked) => {
              if (checked) {
                selectEnrollmentType(enrollmentType)
              } else {
                unselectEnrollmentType(enrollmentType)
              }
            }}
            data-qa={dataQa}
          />
        </Grid>
      )
    })}

    <Grid item sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 4 }} xs={12} />
  </>
)

interface UsageFilterProps {
  isHsaEligible: YesOrNo | ""
  setIsHsaEligible: (value: YesOrNo | "") => void
}

const UsageFilter = ({ isHsaEligible, setIsHsaEligible }: UsageFilterProps) => (
  <>
    <Grid item xs={12} sx={{ mt: 8 }}>
      <RadioGroupCard
        name="isHsaEligible"
        formName={baseDataQa}
        elements={YES_NO_OPTIONS}
        value={isHsaEligible}
        handleChange={(_, value) => {
          setIsHsaEligible(value)
        }}
        label={
          <FilterExplanation title="HSA eligible">
            <TextExplanation
              sectionName="hsa"
              title="HSA"
              description="This account offers triple tax savings, where you can contribute pre-tax dollars, pay no taxes on earnings, and withdraw the money tax-free now or in retirement to pay for qualified medical expenses. Qualified medical costs paid by an HSA is always tax-free. Non-qualified withdrawals at age 65 are taxed at ordinary income rates and non-qualified withdrawals under age 65 carry a 20% penalty in addition to the income tax."
            />
          </FilterExplanation>
        }
        labelVariant="h5"
      />
    </Grid>
    <Grid item sx={{ mt: 4 }} xs={12} />
  </>
)

interface UsePlanFilterStateOptions {
  filterPreferences: FilterPreferences
  setFilterPreferences: Dispatch<SetStateAction<FilterPreferences>>
}

const usePlanFilterState = ({
  filterPreferences: {
    preferredPlanTypes,
    categories,
    enrollmentTypes,
    doctorPreferences,
    hospitalPreferences,
    drugPreferences,
    carrierPreferences,
    isHsaEligible,
    ...prefs
  },
  setFilterPreferences,
}: UsePlanFilterStateOptions) => {
  const createUpdatedFilter = (): FilterPreferences => ({
    ...prefs,
    doctorPreferences,
    hospitalPreferences,
    drugPreferences,
    preferredPlanTypes,
    carrierPreferences,
    categories,
    isHsaEligible,
    enrollmentTypes,
  })

  const clearFilters = () => {
    setFilterPreferences(current => ({
      ...current,
      doctorPreferences: [],
      hospitalPreferences: [],
      drugPreferences: [],
      preferredPlanTypes: [],
      carrierPreferences: [],
      categories: [],
      isHsaEligible: "",
      enrollmentTypes: [],
    }))
  }

  const selectPlanType = (planType: PlanType) => {
    setFilterPreferences(current => ({
      ...current,
      preferredPlanTypes: [...current.preferredPlanTypes, planType],
    }))
  }

  const unselectPlanType = (planType: PlanType) => {
    setFilterPreferences(current => ({
      ...current,
      preferredPlanTypes: current.preferredPlanTypes.filter(selectedPlanType => selectedPlanType !== planType),
    }))
  }

  const selectMetalTier = (category: PlanSearchCategory) => {
    setFilterPreferences(current => ({
      ...current,
      categories: [...current.categories, category],
    }))
  }

  const unselectMetalTier = (category: PlanSearchCategory) => {
    setFilterPreferences(current => ({
      ...current,
      categories: current.categories.filter(selectedCategory => selectedCategory !== category),
    }))
  }

  const selectEnrollmentType = (enrollmentType: EnrollmentType | PlanExtraInformationType) => {
    setFilterPreferences(current => ({
      ...current,
      enrollmentTypes: [...current.enrollmentTypes, enrollmentType],
    }))
  }

  const unselectEnrollmentType = (enrollmentType: EnrollmentType | PlanExtraInformationType) => {
    setFilterPreferences(current => ({
      ...current,
      enrollmentTypes: current.enrollmentTypes.filter(
        selectedEnrollmentType => selectedEnrollmentType !== enrollmentType
      ),
    }))
  }

  const addCarrierSelection = (selection: Carrier) => {
    setFilterPreferences(current => {
      const prevCarriers = current.carrierPreferences
      const updatedCarriers = prevCarriers.some(carrier => carrier.id === selection.id)
        ? prevCarriers
        : [...prevCarriers, selection]

      return {
        ...current,
        carrierPreferences: updatedCarriers,
      }
    })
  }

  const removeCarrierSelection = (removeId: string) => {
    setFilterPreferences(current => ({
      ...current,
      carrierPreferences: current.carrierPreferences.filter(selectedCarrier => selectedCarrier.id !== removeId),
    }))
  }

  const setIsHsaEligible = (value: FilterPreferences["isHsaEligible"]) => {
    setFilterPreferences(current => ({
      ...current,
      isHsaEligible: value,
    }))
  }

  const handlePreferencesChange = (
    doctorSelections: FilterPreferences["doctorPreferences"],
    hospitalSelections: FilterPreferences["hospitalPreferences"],
    prescriptionSelections: FilterPreferences["drugPreferences"]
  ) => {
    setFilterPreferences(current => ({
      ...current,
      doctorPreferences: doctorSelections,
      hospitalPreferences: hospitalSelections,
      drugPreferences: prescriptionSelections,
    }))
  }

  return {
    selectedPlanTypes: preferredPlanTypes,
    selectPlanType,
    unselectPlanType,
    selectedMetalTiers: categories,
    selectMetalTier,
    unselectMetalTier,
    selectedEnrollmentTypes: enrollmentTypes,
    selectEnrollmentType,
    unselectEnrollmentType,
    doctorPreferences,
    hospitalPreferences,
    drugPreferences,
    carrierSelections: carrierPreferences,
    addCarrierSelection,
    removeCarrierSelection,
    isHsaEligible,
    setIsHsaEligible,
    clearFilters,
    createUpdatedFilter,
    handlePreferencesChange,
  }
}

interface FilterButtonsProps {
  clearFilters: () => void
}

const FilterButtons = ({ clearFilters }: FilterButtonsProps) => (
  <Grid container justifyContent="flex-start" spacing={2}>
    <Grid item>
      <Button
        variant="outlined"
        sx={{ color: "colors.darkBody", borderColor: "colors.darkBody" }}
        onClick={clearFilters}
        data-qa={`${baseDataQa}-clear-all-button`}
      >
        Clear all
      </Button>
    </Grid>
  </Grid>
)

export interface PlanFilterProps {
  availableCarriers: Carrier[]
  filterPreferences: FilterPreferences
  setFilterPreferences: Dispatch<SetStateAction<FilterPreferences>>
}

export const PlanFilter = ({
  availableCarriers,
  filterPreferences,

  setFilterPreferences,
}: PlanFilterProps) => {
  const {
    selectedPlanTypes,
    selectPlanType,
    unselectPlanType,
    selectedMetalTiers,
    selectMetalTier,
    unselectMetalTier,
    selectedEnrollmentTypes,
    selectEnrollmentType,
    unselectEnrollmentType,
    doctorPreferences,
    hospitalPreferences,
    drugPreferences,
    carrierSelections,
    addCarrierSelection,
    removeCarrierSelection,
    isHsaEligible,
    setIsHsaEligible,
    clearFilters,
    handlePreferencesChange,
  } = usePlanFilterState({ filterPreferences, setFilterPreferences })

  const { user } = useAuth()
  const companyId = user?.company?.companyId as Uuid
  const { isAutoPay } = useIsCompanyAutoPay(companyId)
  const [showModal, setShowModal] = useState(false)

  return (
    <Grid container data-qa="plan-filter">
      <Grid item display="flex" justifyContent="space-between" alignItems="center" xs={12} mt={8}>
        <Typography variant="h5" display="inline" data-qa={`${baseDataQa}-title`}>
          Filters
        </Typography>
        <Box>
          <FilterButtons clearFilters={clearFilters} />
        </Box>
      </Grid>

      <NetworkFilter
        showModal={showModal}
        doctorPreferences={doctorPreferences}
        hospitalPreferences={hospitalPreferences}
        drugPreferences={drugPreferences}
        handlePreferencesChange={handlePreferencesChange}
        onClose={() => setShowModal(false)}
      />

      <NetworkSection
        doctorSelections={doctorPreferences}
        hospitalSelections={hospitalPreferences}
        prescriptionSelections={drugPreferences}
        handleClick={() => setShowModal(true)}
      />

      <PlanTypeFilter
        selectedPlanTypes={selectedPlanTypes}
        selectPlanType={selectPlanType}
        unselectPlanType={unselectPlanType}
      />
      <CarrierFilter
        availableCarriers={availableCarriers}
        carrierSelections={carrierSelections}
        addCarrierSelection={addCarrierSelection}
        removeCarrierSelection={removeCarrierSelection}
      />
      <MetalTierFilter
        selectedMetalTiers={selectedMetalTiers}
        selectMetalTier={selectMetalTier}
        unselectMetalTier={unselectMetalTier}
      />

      <UsageFilter isHsaEligible={isHsaEligible} setIsHsaEligible={setIsHsaEligible} />

      <EnrollmentTypesFilter
        selectedEnrollmentTypes={selectedEnrollmentTypes}
        selectEnrollmentType={selectEnrollmentType}
        unselectEnrollmentType={unselectEnrollmentType}
        isAutoPay={isAutoPay}
      />
    </Grid>
  )
}
