import { DataQa } from "@/utils/dataQa"
import { Card, Drawer, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { uniqBy } from "lodash"
import { useNavigate } from "react-router-dom"
import { HOUSEHOLD_INCOME, RECOMMENDED_PLANS, RECOMMENDED_PLANS_DEFAULT_LIMIT } from "../../benefitsElectionConstants"
import { useShoppingSession, useShoppingUrl } from "../../benefitsElectionService"
import { createRecommendedFilterPreferences } from "../../benefitsElectionUtils"
import { AlreadyHaveCoverage } from "../../components/AlreadyHaveCoverage"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"
import { EnrollmentBanner } from "../../components/EnrollmentBanner"
import { useSearchPlansState } from "../../hooks/useSearchPlansState"
import { useFindPlanDrawer } from "../FindPlanLayout"
import { PlanFilter } from "./PlanFilter"
import { PlanGrid } from "./PlanGrid"

export const baseDataQa = "recommended-plans" as DataQa

const drawerWidth = {
  xs: 350,
  md: 320,
  lg: 350,
} as const

export const FindPlan = () => {
  const shoppingSessionId = useShoppingSession()
  const shoppingUrl = useShoppingUrl()
  const navigate = useNavigate()
  const { drawerOpen: mobileOpen, setDrawerOpen: setMobileOpen } = useFindPlanDrawer()

  const searchPlansState = useSearchPlansState({
    initialPage: 0,
    defaultPlansPerPage: RECOMMENDED_PLANS_DEFAULT_LIMIT,
    createFilterPreferences: createRecommendedFilterPreferences,
  })
  const { filterPreferences, allPlans, setFilterPreferences } = searchPlansState
  const availableCarriers = uniqBy(
    allPlans.map(p => p.carrier),
    "id"
  )
  const previous = shoppingUrl + HOUSEHOLD_INCOME

  const sideContent = (
    <Box p={4}>
      <Box pb={6}>
        <AlreadyHaveCoverage />
      </Box>
      <PlanFilter
        availableCarriers={availableCarriers}
        filterPreferences={filterPreferences}
        setFilterPreferences={setFilterPreferences}
      />
    </Box>
  )

  return (
    <Grid container>
      <Box py={4} px={8}>
        <Box display="flex">
          <Box width={{ md: drawerWidth.md, lg: drawerWidth.lg }} flexShrink={{ md: 0 }}>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={() => setMobileOpen(false)}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth.md },
              }}
            >
              {sideContent}
            </Drawer>
            <Box display={{ xs: "none", md: "block" }}>
              <Card sx={{ bgcolor: "colors.regionGray" }}>{sideContent}</Card>
            </Box>
          </Box>
          <Box
            component="main"
            flexGrow={1}
            width={{ lg: `calc(100% - ${drawerWidth.md}px)` }}
            pl={{ md: "1.5rem", lg: "2rem" }}
          >
            <Grid container justifyContent="space-between" alignItems="center" pb="2.25rem">
              <Grid item xs={12} lg={6} order={{ xs: "2", lg: "1" }}>
                <Typography variant="h1" display="inline">
                  Find my health plan
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6} order={{ xs: "1", lg: "2" }} pb={{ xs: "1.5rem", lg: "0" }}>
                <EnrollmentBanner variant="inside-shopping-flow" />
              </Grid>
            </Grid>
            <BenefitsElectionStep
              title="Here are your recommended plans"
              previous={previous}
              hideTitle
              hideContinue
              handleSkip={() => navigate(`/benefits-election/${shoppingSessionId}/waive-coverage`)}
              skipLabel="Waive, I'm not interested"
              sx={{ minHeight: "" }}
            >
              <PlanGrid data-qa={baseDataQa} planView={RECOMMENDED_PLANS} searchPlansState={searchPlansState} />
            </BenefitsElectionStep>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}
