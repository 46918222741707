import { AppContentWrapper } from "@/components/AppContentWrapper"
import { DrawerWrapper } from "@/components/DrawerWrapper"
import { Footer } from "@/components/Footer"
import { MainContentWrapper } from "@/components/MainContentWrapper"
import { Navbar } from "@/components/Navbar/Navbar"
import { RootWrapper } from "@/components/RootWrapper"
import { Sidebar } from "@/components/Sidebar/Sidebar"
import { SidebarItem } from "@/components/Sidebar/types"
import { DRAWER_WIDTH } from "@/constants"
import { AuthGuard } from "@/features/Auth/guards/AuthGuard"
import { useCompanyId } from "@/features/Auth/useAuth"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import {
  AccountBalanceWalletOutlined,
  ChecklistOutlined,
  CreditCardOutlined,
  DescriptionOutlined,
  InsertInvitationOutlined,
  PeopleOutlined,
} from "@mui/icons-material"
import { Box } from "@mui/material"
import { useMemo, useState } from "react"
import { Outlet } from "react-router-dom"
import {
  AUTOPAY,
  EMPLOYEES,
  EMPLOYER_ONBOARDING,
  HRA_DOCUMENTS,
  INVITATIONS,
  SUBSCRIPTION_BILLING,
} from "../employerOnboardingConstants"
import { useOnboardingStatuses } from "../employerOnboardingService"

const getEmployerOnboardingSteps = (isBillingReady: boolean, showAutoPay: boolean): SidebarItem[] => [
  {
    title: "Getting Started",
    icon: ChecklistOutlined,
    href: `/${EMPLOYER_ONBOARDING}/`,
    parentLink: true,
    disableDropdown: true,
    children: [
      {
        title: "HRA Documents",
        icon: DescriptionOutlined,
        href: `/${EMPLOYER_ONBOARDING}/${HRA_DOCUMENTS}`,
      },
      {
        title: "Employees",
        icon: PeopleOutlined,
        href: `/${EMPLOYER_ONBOARDING}/${EMPLOYEES}`,
      },
      {
        title: "AutoPay",
        icon: AccountBalanceWalletOutlined,
        href: `/${EMPLOYER_ONBOARDING}/${AUTOPAY}`,
        hidden: !showAutoPay,
      },
      {
        title: "Invitations",
        icon: InsertInvitationOutlined,
        href: `/${EMPLOYER_ONBOARDING}/${INVITATIONS}`,
      },
      {
        title: "Subscription billing",
        icon: CreditCardOutlined,
        href: `/${EMPLOYER_ONBOARDING}/${SUBSCRIPTION_BILLING}`,
        disabled: !isBillingReady,
      },
    ],
  },
]

export const useEmployerOnboardingItems = () => {
  const companyId = useCompanyId(false)!
  const { data: company, isLoading: isCompanyLoading } = useGetCompany(companyId)
  const { isBillingReady } = useOnboardingStatuses()
  const showAutoPay = !isCompanyLoading && !!company?.companyInfo.autoPayAvailableOption

  return useMemo(() => getEmployerOnboardingSteps(!!isBillingReady, showAutoPay), [isBillingReady, showAutoPay])
}

export const EmployerOnboardingLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const sidebarItems = useEmployerOnboardingItems()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <AuthGuard>
      <RootWrapper>
        <DrawerWrapper>
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Sidebar
              PaperProps={{ style: { width: DRAWER_WIDTH } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={sidebarItems}
              showFooter={false}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Sidebar PaperProps={{ style: { width: DRAWER_WIDTH } }} items={sidebarItems} showFooter={false} />
          </Box>
        </DrawerWrapper>
        <AppContentWrapper>
          <Navbar onDrawerToggle={handleDrawerToggle} hideProfileItem />
          <MainContentWrapper>
            <Outlet />
          </MainContentWrapper>
          <Footer />
        </AppContentWrapper>
      </RootWrapper>
    </AuthGuard>
  )
}
