import { DrawerForm } from "@/components/DrawerForm"
import { LoadingContentArea } from "@/components/LoadingContentArea"
import { getActiveCompany } from "@/features/Auth/services/authService"
import { useAuth } from "@/features/Auth/useAuth"
import { useGetCompany } from "@/features/CreateCompany/components/Steps/Setup/setupService"
import { useGetPersonById } from "@/features/People/peopleService"
import { AboutCard, ContactCard } from "@/features/Profile/pages/Profile"
import { getDisplayPhoneNumber } from "@/utils/formatting"
import { KeyboardArrowLeft } from "@mui/icons-material"
import { Button, Grid, Typography } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { PersonModel } from "../peopleTypes"

export interface PeopleProfileDrawerProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  personProfileId: string
}

const getRoleFromPerson = (person: PersonModel) => person?.roles?.map(role => role.name).join(", ")

export const PeopleProfileDrawer = ({ open, setOpen, personProfileId }: PeopleProfileDrawerProps) => {
  const { user } = useAuth()
  const companyId = getActiveCompany(user)?.companyId || ""
  const { data: person, isLoading: isLoadingPerson } = useGetPersonById(companyId, personProfileId)
  const { data: company, isLoading: isCompanyLoading } = useGetCompany(companyId)

  // About info
  const companyName = company?.companyInfo.companyName ?? ""
  const fullAddress = person?.location ?? ""
  // FUTURE SEG-5124 fix API for returing list of roles
  const role = getRoleFromPerson(person!) ?? ""

  // Contact info
  const emailNew = person?.email ?? ""
  const phoneNumber = getDisplayPhoneNumber(person?.phoneNumber ?? "")

  const isEmployeeInformationLoading = isLoadingPerson || isCompanyLoading

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  return (
    <DrawerForm
      open={open}
      onClose={toggleDrawer(false)}
      paperStyle={{ padding: "2rem", width: "93%", maxWidth: "37.5rem" }}
      data-qa="drawer-profile"
    >
      <Button
        startIcon={<KeyboardArrowLeft />}
        color="inherit"
        onClick={() => {
          setOpen(false)
        }}
        sx={{ width: "6rem" }}
      >
        Back
      </Button>
      <Grid container>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Typography variant="h2tiempos" gutterBottom>
            Profile
          </Typography>
        </Grid>
        {isEmployeeInformationLoading ? (
          <LoadingContentArea data-qa="loading-employee-informtation" />
        ) : (
          <>
            <Grid item xs={12}>
              <AboutCard companyName={companyName} fullAddress={fullAddress} role={role} />
            </Grid>
            <Grid item xs={12}>
              <ContactCard emailNew={emailNew} phoneNumber={phoneNumber} />
            </Grid>
          </>
        )}
      </Grid>
    </DrawerForm>
  )
}
