import { useNotifications } from "@/services/notificationService"
import { UsaState } from "@/utils/States"
import { Patch, Uuid } from "@/utils/types"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { clamp } from "lodash"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { ACTIVE } from "../Funding/fundingConstants"
import { useGetFundingStatus } from "../Funding/fundingService"
import { BENEFITS_ELECTION } from "./benefitsElectionConstants"
import {
  addWaiveCoverage,
  checkCoverageForAllMembers,
  closeShoppingSession,
  createCarrierEnrollmentQuestionAnswers,
  createHealthBenefitsElection,
  deleteCarrierEnrollmentQuestionAnswers,
  deleteHealthBenefitsElection,
  getAchDetails,
  getAllowances,
  getCarrierEnrollmentQuestionAnswers,
  getCarriers,
  getShoppingSession,
  getShoppingSessions,
  manageShoppingPersons,
  submitSelfPaymentInformation,
  updateAllowance,
  updateCarrierEnrollmentQuestionAnswers,
  updateHealthBenefitsElection,
} from "./benefitsElectionEndpoints"
import { useBenefitsElectionStore } from "./benefitsElectionStore"
import {
  Allowance,
  CarrierEnrollmentQuestionAnswer,
  DoctorPreference,
  DrugId,
  DrugPreference,
  GetAllowancesResponse,
  HealthBenefitsElection,
  HospitalPreference,
  ManageShoppingPersonPayload,
  ProviderPreferenceId,
  SelfPaymentInformationRequest,
  ShoppingPerson,
  ShoppingUrl,
  UpdateAllowancePayload,
  WaiveCoveragePayload,
} from "./benefitsElectionTypes"
import { createGetAllowancesPayload } from "./benefitsElectionUtils"
import { useShoppingCompleteStore } from "./shoppingCompleteStore"

export const useAddWaiveCoverage = (shoppingSessionId: string, planYear: number) => {
  const setWaivedPlanYear = useShoppingCompleteStore(state => state.setWaivedPlanYear)

  return useMutation({
    mutationFn: (values: WaiveCoveragePayload) => addWaiveCoverage(shoppingSessionId, values),
    onSuccess: () => setWaivedPlanYear(planYear),
  })
}

export const useCloseShoppingSession = (shoppingSessionId: string, planYear: number) => {
  const setCompletedPlanYear = useShoppingCompleteStore(state => state.setCompletedPlanYear)

  return useMutation({
    mutationFn: () => closeShoppingSession(shoppingSessionId),
    onSuccess: () => setCompletedPlanYear(planYear),
  })
}

export const useUpdateAllowance = (shoppingSessionId: string) =>
  useMutation({
    mutationFn: (values: UpdateAllowancePayload) => updateAllowance(shoppingSessionId, values),
  })

export const useManageShoppingPersons = (shoppingSessionId: string) => {
  const { notify } = useNotifications("manage-shopping-persons")

  return useMutation({
    mutationFn: (values: ManageShoppingPersonPayload[]) => manageShoppingPersons(shoppingSessionId, values),
    onSuccess: data => {
      if (data.meta.requestsFailed > 0) {
        throw new Error("Error updating shopping persons")
      }
    },
    onError: error => {
      notify(
        "An error occurred. Please try again later. If the issue persists, contact support for assistance.",
        "error"
      )
      console.error(error)
    },
  })
}

const THIRTY_MINUTES_MS = 1000 * 60 * 30

export const useGetAllowances = (
  employeeId: string,
  companyId: string,
  planYear: number,
  employee: ShoppingPerson,
  familyMembers?: ShoppingPerson[]
) => {
  const allowancesPayload = useMemo(
    () => createGetAllowancesPayload(employee, familyMembers),
    [employee, familyMembers]
  )

  return useQuery<GetAllowancesResponse, Error, Allowance[]>({
    queryKey: ["companies", companyId, "employees", employeeId, "allowances", planYear, allowancesPayload],
    queryFn: () => getAllowances(companyId, planYear, employeeId, allowancesPayload),
    enabled: !!companyId && !!employeeId && !!planYear,
    select: data => data.allowances,
    staleTime: THIRTY_MINUTES_MS,
    refetchOnWindowFocus: false,
  })
}

export const useCreateHealthBenefitsElection = (shoppingSessionId: Uuid) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (electionInfo: Omit<HealthBenefitsElection, "id">) =>
      createHealthBenefitsElection(shoppingSessionId, electionInfo),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["checkCoverageForAllMembers", shoppingSessionId],
      })
    },
  })
}

export const useUpdateHealthBenefitsElection = (shoppingSessionId: Uuid) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (healthBenefitsElection: Patch<HealthBenefitsElection>) =>
      updateHealthBenefitsElection(shoppingSessionId, healthBenefitsElection),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["checkCoverageForAllMembers", shoppingSessionId],
      })
    },
  })
}

export const useDeleteHealthBenefitsElection = (shoppingSessionId: Uuid) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (healthBenefitsElectionId: Uuid) =>
      deleteHealthBenefitsElection(shoppingSessionId, healthBenefitsElectionId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["checkCoverageForAllMembers", shoppingSessionId],
      })
    },
  })
}

export const useGetCarrierEnrollmentQuestionAnswers = (electionId: string) =>
  useQuery({
    queryKey: ["election", electionId, "answers"],
    queryFn: () => getCarrierEnrollmentQuestionAnswers(electionId),
    enabled: !!electionId,
  })

export const useCreateCarrierEnrollmentQuestionAnswers = (electionId: string) =>
  useMutation({
    mutationFn: (answers: CarrierEnrollmentQuestionAnswer[]) =>
      createCarrierEnrollmentQuestionAnswers(electionId, answers),
  })

export const useUpdateCarrierEnrollmentQuestionAnswers = (electionId: string) =>
  useMutation({
    mutationFn: (answers: CarrierEnrollmentQuestionAnswer[]) =>
      updateCarrierEnrollmentQuestionAnswers(electionId, answers),
  })

export const useDeleteCarrierEnrollmentQuestionAnswers = (electionId: string) =>
  useMutation({
    mutationFn: (answers: CarrierEnrollmentQuestionAnswer[]) =>
      deleteCarrierEnrollmentQuestionAnswers(electionId, answers),
  })

export const useUpdateCarrierQuestions = () =>
  useMutation({
    mutationFn: (values: any) =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve(values)
        }, 2000)
      }),
  })

export const useMyInfo = () => {
  const currentYear = new Date().getFullYear()

  return useBenefitsElectionStore(state => ({
    doctors: state.employee.doctors,
    addDoctor: (doctor: DoctorPreference) =>
      state.setEmployeeDoctors([...state.employee.doctors.filter(e => e.id !== doctor.id), doctor]),
    removeDoctor: (removeId: ProviderPreferenceId) =>
      state.setEmployeeDoctors(state.employee.doctors.filter(doctor => doctor.id !== removeId)),

    hospitals: state.employee.hospitals,
    addHospital: (hospital: HospitalPreference) =>
      state.setEmployeeHospitals([...state.employee.hospitals.filter(e => e.id !== hospital.id), hospital]),
    removeHospital: (removeId: ProviderPreferenceId) =>
      state.setEmployeeHospitals(state.employee.hospitals.filter(hospital => hospital.id !== removeId)),

    prescriptions: state.employee.prescriptions,
    addPrescription: (drug: DrugPreference) =>
      state.setEmployeePrescriptions([...state.employee.prescriptions.filter(e => e.id !== drug.id), drug]),
    removePrescription: (removeId: DrugId) =>
      state.setEmployeePrescriptions(state.employee.prescriptions.filter(drug => drug.id !== removeId)),

    planSearchParams: {
      zipCode: state.employee.personalInformation?.zipCode?.zipCode ?? "00000",
      state: state.employee.personalInformation?.zipCode?.state ?? "",
      // FUTURE: Examine API returning invalid years
      planYear: clamp(state.currentShoppingSession.planYear, currentYear, currentYear + 1),
      radius: state.radius,
    },
  }))
}

// SAFETY: We can assert that the shopping session is not null since we validate and correct it if necessary in the BenefitsElectionGuard
export const useShoppingSession = () => useParams<{ shoppingSessionId: string }>().shoppingSessionId as Uuid

export const useShoppingUrl = () => {
  const shoppingSessionId = useShoppingSession()

  return useMemo((): ShoppingUrl => `/${BENEFITS_ELECTION}/${shoppingSessionId}/`, [shoppingSessionId])
}

export const useIsCompanyAutoPay = (companyId: Uuid) => {
  const fundingStatus = useGetFundingStatus(companyId)
  const isAutoPay = fundingStatus.data === ACTIVE

  return { ...fundingStatus, isAutoPay }
}

export const useGetAchDetails = (
  electionId: string,
  isAutoPay: boolean,
  premiumAmountInCents: number | undefined = undefined
) =>
  useQuery({
    queryKey: ["achDetails", electionId],
    queryFn: () => getAchDetails(electionId, premiumAmountInCents),
    enabled: !!electionId && isAutoPay,
    retryDelay: 12000,
    retry: 10,
  })

export const useRefreshCurrentShoppingSession = (employmentId: string) => {
  const currentShoppingSession = useBenefitsElectionStore(state => state.currentShoppingSession)
  const setCurrentShoppingSession = useBenefitsElectionStore(state => state.setCurrentShoppingSession)
  const shoppingSessionId = currentShoppingSession.id
  const enrollmentTimePeriodId = currentShoppingSession.enrollmentTimePeriod.id

  const refreshCurrentShoppingSession = async () => {
    const sessions = await getShoppingSessions(employmentId, enrollmentTimePeriodId, false)
    const updatedSession = sessions.find(s => s.id === shoppingSessionId)
    if (updatedSession) {
      setCurrentShoppingSession(updatedSession)
    }
  }

  return refreshCurrentShoppingSession
}

export const useSubmitSelfPaymentInformation = (employmentId: Uuid, electionId: Uuid) =>
  useMutation({
    mutationFn: (request: SelfPaymentInformationRequest) =>
      submitSelfPaymentInformation(employmentId, electionId, request),
  })

export const useCheckCoverageForAllMembers = (shoppingSessionId: string) =>
  useQuery({
    queryKey: ["checkCoverageForAllMembers", shoppingSessionId],
    queryFn: () => checkCoverageForAllMembers(shoppingSessionId),
  })

export const useGetCarrierOptions = (state: UsaState | "" | undefined) =>
  useQuery({
    queryKey: ["carrier-options", state],
    queryFn: () => getCarriers("", state),
    enabled: !!state,
  })

export const useGetShoppingSession = (shoppingSessionId?: Uuid) =>
  useQuery({
    queryKey: ["shopping-sessions", shoppingSessionId],
    queryFn: () => getShoppingSession(shoppingSessionId!),
    enabled: !!shoppingSessionId,
  })
