import { MAX_CARDS_PER_ROW } from "@/constants"
import { Box, Checkbox, FormControl, FormGroup, Grid, Typography } from "@mui/material"
import { useFormikContext } from "formik"

interface CheckBoxCardProps {
  title: string
  subtitle?: string
  name: string
  handleChange: (field: string, value: boolean) => void
  dataQa: string
  small?: boolean
}

export const CheckBoxCard = ({ handleChange, dataQa, small, name, title, subtitle }: CheckBoxCardProps) => {
  const { values } = useFormikContext<any>()
  const isSelected = values[name]

  return (
    <Box
      onClick={() => {
        handleChange(name, !isSelected)
      }}
      data-qa={dataQa}
      sx={{
        border: "1px solid",
        borderColor: isSelected ? "colors.borderGreen" : "colors.borderGray",
        borderRadius: "5px",
        backgroundColor: isSelected ? "colors.lightTealShade" : "colors.regionGray",
        cursor: "pointer",
        minHeight: small ? "3.125rem" : "5rem ",
        pl: 4,
        pr: isSelected ? 2 : 4,
        py: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="body1"> {title}</Typography>
        <Typography variant="caption" sx={{ display: "block", lineHeight: "1.162rem" }}>
          {subtitle}
        </Typography>
      </Box>
      {isSelected && (
        <Checkbox color="primary" data-qa={dataQa} size={small ? "small" : "medium"} checked={isSelected} />
      )}
    </Box>
  )
}

interface CheckBoxGroupCardProps {
  elements: { title: string; value: string }[]
  formName: string
  small?: boolean
  handleChange: (field: string, value: boolean) => void
  error: boolean
}

export const CheckBoxGroupCard = ({ small, formName, elements, error, handleChange }: CheckBoxGroupCardProps) => {
  const spacePerCard = elements.length <= MAX_CARDS_PER_ROW ? 12 / elements.length : 12 / MAX_CARDS_PER_ROW

  return (
    <Grid item xs={12} minHeight={small ? "" : "4.75rem"}>
      <FormControl required error={error} component="fieldset" variant="standard" fullWidth>
        <FormGroup>
          <Grid container spacing={4}>
            {elements.map(element => (
              <Grid item xs={12} sm={6} md={spacePerCard} key={element.value}>
                <CheckBoxCard
                  key={element.value}
                  title={element.title}
                  name={element.value}
                  handleChange={handleChange}
                  dataQa={`${formName}-${element.value}-checkBoxCard`}
                  small={small}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
    </Grid>
  )
}
