import {
  addCompany,
  CompanyModel,
  getCompany,
  getCountiesFromZip,
  updateCompany,
} from "@/features/CreateCompany/createCompanyEndpoints"
import { Uuid } from "@/utils/types"
import {
  validateAle,
  validateAleInCreateCompany,
  validateBenefitAdministratorFirstName,
  validateBenefitAdministratorLastName,
  validateBusinessStructure,
  validateCity,
  validateCompanyName,
  validateConfirmEin,
  validateEin,
  validateEmail,
  validateIndustry,
  validateMobilePhoneNumber,
  validateState,
  validateStreet1,
  validateStreet2,
  validateZip,
} from "@/utils/validations"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import * as Yup from "yup"

export const createCompanyValidationSchema = Yup.object({
  companyName: validateCompanyName,
  ein: validateEin,
  aleQuestion: validateAleInCreateCompany,
  isAssisted: validateAleInCreateCompany,
  businessStructure: validateBusinessStructure,
  industry: validateIndustry,
  confirmEin: validateConfirmEin.oneOf([Yup.ref("ein")], "EIN must match"),
  companyAddress: validateStreet1,
  companyAddressLine2: validateStreet2,
  companyCity: validateCity,
  companyState: validateState,
  companyZip: validateZip,
  benefitsAdministratorFirstName: validateBenefitAdministratorFirstName,
  benefitsAdministratorLastName: validateBenefitAdministratorLastName,
  benefitsAdministratorEmail: validateEmail,
  benefitsAdministratorPhoneNumber: validateMobilePhoneNumber.required("Phone Number is required."),
  autoPayAvailableOption: Yup.boolean(),
  submit: Yup.string(),
})

export const tcHubcreateCompanyValidationSchema = Yup.object({
  companyName: validateCompanyName,
  ein: validateEin,
  businessStructure: validateBusinessStructure,
  industry: validateIndustry,
  confirmEin: validateConfirmEin.oneOf([Yup.ref("ein")], "EIN must match"),
  companyAddress: validateStreet1,
  companyAddressLine2: validateStreet2,
  companyCity: validateCity,
  companyState: validateState,
  companyZip: validateZip,
  benefitsAdministratorFirstName: validateBenefitAdministratorFirstName,
  benefitsAdministratorLastName: validateBenefitAdministratorLastName,
  benefitsAdministratorEmail: validateEmail,
  benefitsAdministratorPhoneNumber: validateMobilePhoneNumber.required("Phone Number is required."),
  submit: Yup.string(),
})

export const updateCompanyValidationSchema = Yup.object({
  companyName: validateCompanyName,
  ein: Yup.string().required(),
  businessStructure: validateBusinessStructure,
  aleQuestion: validateAle,
  isAssisted: validateAleInCreateCompany,
  industry: validateIndustry,
  companyAddress: validateStreet1,
  companyAddressLine2: validateStreet2,
  companyCity: validateCity,
  companyState: validateState,
  companyZip: validateZip,
  benefitsAdministratorFirstName: validateBenefitAdministratorFirstName,
  benefitsAdministratorLastName: validateBenefitAdministratorLastName,
  benefitsAdministratorEmail: validateEmail,
  benefitsAdministratorPhoneNumber: validateMobilePhoneNumber.required("Phone Number is required."),
  autoPayAvailableOption: Yup.boolean().required(),
  submit: Yup.string(),
})

export type CreateCompanyValues = Yup.InferType<typeof createCompanyValidationSchema>
export type UpdateCompanyValues = Yup.InferType<typeof updateCompanyValidationSchema>

export const companyPayload = (
  formValues: CreateCompanyValues | UpdateCompanyValues,
  editCompany?: Uuid
): Partial<CompanyModel> => {
  const payload: Partial<CompanyModel> = {}

  if (editCompany) payload.id = editCompany
  payload.companyInfo = {
    companyName: formValues.companyName,
    companyType: formValues.businessStructure,
    ein: formValues.ein,
    industry: formValues.industry,
    isALE: !!formValues.aleQuestion,
    isAssisted: !!formValues.isAssisted,
    autoPayAvailableOption: formValues.autoPayAvailableOption,
  }
  payload.companyAddress = {
    streetAddress: formValues.companyAddress,
    suiteApt: formValues?.companyAddressLine2 === "" ? undefined : (formValues?.companyAddressLine2 as string),
    city: formValues.companyCity,
    state: formValues.companyState,
    zip: formValues.companyZip,
  }
  payload.benefitsAdministrator = {
    firstName: formValues.benefitsAdministratorFirstName,
    lastName: formValues.benefitsAdministratorLastName,
    email: formValues.benefitsAdministratorEmail,
    phoneNumber: formValues.benefitsAdministratorPhoneNumber,
  }

  return payload
}

export const useCreateCompany = () =>
  useMutation({ mutationFn: (values: CreateCompanyValues) => addCompany(companyPayload(values)) })

export const useUpdateCompany = (companyId: Uuid) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: UpdateCompanyValues) => updateCompany(companyPayload(values, companyId)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getCompany"] })
      queryClient.invalidateQueries({ queryKey: ["getCurrentClasses"] })
    },
  })
}

export const useGetCompany = (id: string) => useQuery({ queryKey: ["getCompany", id], queryFn: () => getCompany(id) })

export const useGetCountiesFromZipCode = (zipCode: string) =>
  useQuery({ queryKey: ["getCountiesFromZipCode"], queryFn: () => getCountiesFromZip(zipCode) })
