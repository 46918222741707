import {
  EMPLOYEE_PURCHASED,
  INDIVIDUAL_HEALTH_INSURANCE,
  PLAN_SELECTED,
} from "@/features/BenefitsElection/benefitsElectionConstants"
import {
  getCarriers,
  getHealthBenefitElection,
  searchHealthBenefitElections,
} from "@/features/BenefitsElection/benefitsElectionEndpoints"
import { Carrier } from "@/features/BenefitsElection/benefitsElectionTypes"
import { getAdminDocDownloadUrl } from "@/features/Documents/documentsEndpoints"
import { getPersonDetailsByEmploymentId } from "@/features/TCHub/tcHubEndpoints"
import { EnrollmentModel } from "@/features/TCHub/tcHubTypes"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { find, get, range, sortBy } from "lodash"
import { DateTime } from "luxon"
import { defaultMemoize as memoize } from "reselect"

export const getCarrierSet = memoize((carriers: Carrier[] | undefined) => {
  const carrierSet = new Map<string, Carrier>()

  if (!carriers) return carrierSet
  for (const carrier of carriers) {
    carrierSet.set(carrier.id, carrier)
  }

  return carrierSet
})

type EnhancedCarrier = Carrier & { stateCodes: string[] }
export const useCarriers = () =>
  useQuery({
    queryKey: ["all-carriers"],
    queryFn: async () => {
      const carriers = await getCarriers("", "")

      return carriers.map(
        carrier =>
          ({
            ...carrier,
            stateCodes: get(carrier, "states") as unknown as string[],
          }) as EnhancedCarrier
      )
    },
  })

export const getCarriersOptions = memoize((carriers: EnhancedCarrier[] | undefined, state: string) => {
  const carriersByState = carriers?.filter(carrier => !state || carrier.stateCodes?.includes(state)) ?? []

  return (
    sortBy(
      carriersByState.map(carrier => ({ value: carrier.id, label: carrier.name })),
      ["label"]
    ) ?? []
  )
})

const checkoutCompletedStatuses = [
  "EMPLOYEE_PURCHASED",
  "APPLICATION_RECEIVED",
  "PENDING_RESPONSE",
  "HOLD_FOR_DELAYED_PAYMENT",
  "HOLD_FOR_SUBMISSION",
  "IN_PROGRESS",
  "POC_PENDING",
  "POC_SUBMITTED",
  "POC_DENIED",
  "SUBMITTED_TO_CARRIER",
]

const tchEnrolledEnrollmentTypes = ["EASY_ENROLL"]

export const isEnrolledThroughTch = (enrollmentType: string) => tchEnrolledEnrollmentTypes.includes(enrollmentType)

export const isCheckoutCompleted = (enrollmentStatus: string) => checkoutCompletedStatuses.includes(enrollmentStatus)

export const filterEnrollments = memoize(
  (
    enrollments: EnrollmentModel[] | undefined,
    filters: {
      state?: string
      carrierId?: string
      enrollmentStatus?: string
      planYear?: string
      planMarket?: string
    }
  ) =>
    enrollments?.filter(enrollment => {
      // Filter out non-individual health insurance enrollments
      // https://takecommandhealth.atlassian.net/browse/SEG-5164
      if (enrollment?.insuranceType && enrollment.insuranceType !== INDIVIDUAL_HEALTH_INSURANCE) {
        return false
      }
      // Filter out non-primary enrollments
      // https://takecommandhealth.atlassian.net/browse/SEG-5851
      if (!enrollment?.isPrimary) {
        return false
      }

      const carrierMatch = !filters?.carrierId || enrollment.carrierId === filters.carrierId
      const stateMatch =
        !filters?.state || enrollment.mailingAddress?.state?.toLowerCase().includes(filters?.state?.toLowerCase())
      const yearMatch = !filters?.planYear || enrollment.planEffectiveDate?.includes(filters?.planYear)

      const enrollmentStatusMatch =
        !filters?.enrollmentStatus ||
        enrollment.enrollmentStatus?.toLowerCase().includes(filters?.enrollmentStatus.toLowerCase())

      const planMarketMatch =
        !filters?.planMarket || enrollment.planMarket?.toLowerCase().includes(filters?.planMarket?.toLowerCase())
      return carrierMatch && stateMatch && yearMatch && enrollmentStatusMatch && planMarketMatch
    }) ?? []
)

export const getYearOptions = memoize(() => {
  const currentYear = new Date().getFullYear()

  return range(currentYear - 1, currentYear + 2).map(year => ({ value: year.toString(), label: year.toString() }))
})

export const getEnrollmentStatusOptions = () => [
  { value: "", label: "All" },
  { value: PLAN_SELECTED, label: "Plan Selected" },
  { value: EMPLOYEE_PURCHASED, label: "Employee Purchased" },
]

type QueryClientType = ReturnType<typeof useQueryClient>
export const getArrayDataInQuery = <TOutput, TInput>(
  queryClient: QueryClientType,
  filters: Parameters<QueryClientType["getQueriesData"]>[0],
  selector: (rows: TInput) => TOutput | null | undefined
) => {
  const queriesData = queryClient.getQueriesData(filters)
  for (const item of queriesData ?? []) {
    const data = item[1] as TInput
    const result = selector(data)
    if (result) return result
  }
  return undefined
}

export const useEnrollmentDetails = (enrollmentId: string | undefined) => {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: ["enrollment", enrollmentId],
    queryFn: () => getHealthBenefitElection(enrollmentId!) as Promise<EnrollmentModel>,
    enabled: !!enrollmentId,
    placeholderData: () =>
      getArrayDataInQuery(
        queryClient,
        {
          predicate: query => query.queryKey[0] === "searchHealthBenefitElections",
        },
        rows =>
          (find(get(rows, "healthBenefitElections"), { id: enrollmentId }) ?? {
            id: enrollmentId,
          }) as EnrollmentModel
      ),
  })
}

export const useEmployeeDetails = (employmentId: string | undefined) =>
  useQuery({
    queryKey: ["employee-details", employmentId],
    queryFn: () => getPersonDetailsByEmploymentId(employmentId!),
    enabled: !!employmentId,
  })

export const useGetEnrollmentSearch = (searchRequest: {
  carrierId?: string
  state?: string
  planYear?: number
  limit?: number
  offset?: number
  employeeName?: string
  employeeEmail?: string
  companyName?: string
}) =>
  useQuery({
    queryKey: ["searchHealthBenefitElections"],
    queryFn: async () => {
      const createdBefore = DateTime.now().toISO()
      const limit = 1000
      let offset = 0
      let total = 0
      const healthBenefitElections: EnrollmentModel[] = []
      do {
        const data = await searchHealthBenefitElections({
          ...searchRequest,
          createdBefore,
          offset,
          limit: 1000,
        })
        total = data.meta.total
        offset += limit
        healthBenefitElections.push(...data.healthBenefitElections)
      } while (offset < total)

      return { healthBenefitElections, meta: { total } }
    },
    enabled: false,
  })

export const useDownloadSignature = (documentId: string | null) =>
  useQuery({
    queryKey: ["download-signature", documentId],
    queryFn: async () => {
      const { url } = await getAdminDocDownloadUrl(documentId!)
      return url
    },
    enabled: !!documentId,
    refetchInterval: 1000 * 60 * 30, // 30 minutes
    staleTime: 1000 * 60 * 30, // 30 minutes
  })
