import { SearchFieldWithChips } from "@/components/SearchFields"
import { takeCommandPrimary } from "@/theme/palette"
import { Grid } from "@mui/material"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { DOCTORS, HOSPITALS, PRESCRIPTIONS } from "../../benefitsElectionConstants"
import { searchProviders } from "../../benefitsElectionEndpoints"
import { useMyInfo, useShoppingUrl } from "../../benefitsElectionService"
import { useBenefitsElectionStore } from "../../benefitsElectionStore"
import { HospitalPreference, MyInfoSearchProps, ProviderPreferenceId } from "../../benefitsElectionTypes"
import { formatProvider } from "../../benefitsElectionUtils"
import { BenefitsElectionStep } from "../../components/BenefitsElectionStep"

export type HospitalsSearchProps = MyInfoSearchProps<HospitalPreference, ProviderPreferenceId>

export const HospitalsSearchField = ({ planYear, zipCode, radius, ...props }: HospitalsSearchProps) => (
  <SearchFieldWithChips<HospitalPreference, ProviderPreferenceId>
    title="Hospitals or medical facilities"
    name="hospitals"
    placeholder="Search for hospitals or medical facilities"
    handleSearch={searchTerm =>
      searchProviders("organization", {
        searchTerm,
        planYear,
        zipCode,
        radius,
      })
    }
    helperText="*Providers can change which insurance plans they accept. We highly recommend calling a hospital or facility to verify they accept a specific plan before enrolling."
    formatOption={hospital => formatProvider(hospital)}
    formatSelection={hospital => ({
      key: hospital.id,
      label: formatProvider(hospital),
    })}
    minimumInputLength={3}
    {...props}
    sx={{ backgroundColor: takeCommandPrimary[200] }}
  />
)

export const Hospitals = () => {
  const { hospitals, addHospital, removeHospital, planSearchParams } = useMyInfo()
  const shoppingUrl = useShoppingUrl()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const currentStep = useBenefitsElectionStore(state => state.currentStep)
  const setCurrentStep = useBenefitsElectionStore(state => state.setCurrentStep)
  const hasSelectedHospitals = hospitals.length > 0
  const previous = shoppingUrl + DOCTORS
  const next = shoppingUrl + PRESCRIPTIONS

  const setNextStep = () => {
    if (currentStep === HOSPITALS) {
      setCurrentStep(PRESCRIPTIONS)
    }
  }

  return (
    <BenefitsElectionStep
      title="Hospitals"
      description="If you have preferred hospitals or medical facilities, you can add them to see if they are in-network with plans. If there are none, please skip."
      previous={previous}
      next={next}
      disabled={!hasSelectedHospitals}
      handleContinue={() => {
        // FUTURE: Show appropriate notification based on API response
        // FUTURE: Refactor to use the notifications service and remove eslint-disable directive
        // eslint-disable-next-line no-restricted-syntax
        enqueueSnackbar(`Successfully added ${hospitals.length} facilities`, {
          variant: "simpleNotification",
          selection: "success",
          dataQa: "add-hospitals",
        })
        setNextStep()
        navigate(next)
      }}
      handleSkip={() => {
        setNextStep()
        navigate(next)
      }}
    >
      <Grid item xs={12} sx={{ mt: 4 }}>
        <HospitalsSearchField
          selections={hospitals}
          handleSelection={addHospital}
          handleDelete={removeHospital}
          {...planSearchParams}
        />
      </Grid>
    </BenefitsElectionStep>
  )
}
